<script lang="ts" setup>
import { VfFormRow } from "@/vf"
import { findDataForName } from "@/vf/utils/SymfonyForm"
import { ref } from "vue"

const props = defineProps<{
    name: string
    schema: any
    horizontal: boolean
}>()
let data = findDataForName(props.name, "TranslationsForm")
if (!data) {
    data = ref({})
}
if (!data.value[props.name]) {
    data.value[props.name] = []
}

console.log("TranslationsForm", props, data)

// schema that renders a single subform in the translations array. outer group is for the array name and inner for the index
function schema(lng: string) {
    for (let i = 0; i < data.value[props.name].length; i++) {
        data.value[props.name][i].id = undefined // TODO find the problem why convertEntityToFormModel does not remove this
        if (data.value[props.name][i].language === lng) {
            return {
                $formkit: "group",
                name: props.name,
                children: [
                    {
                        name: i.toString(),
                        $formkit: "group",
                        children: props.schema,
                    },
                ],
            }
        }
    }
    return null
}

function changeTab(lng: string) {
    console.log("changeTab", lng)
    activeTab.value = lng
}

function addEntry(lng: string) {
    console.log("addEntry", lng)
    data.value[props.name].push({
        language: lng,
    })
    console.log(data.value);
}

// function delEntry(lng: string) {
//     console.log('delEntry', lng)
//     for (let i = 0; i < data[props.name].length; i++) {
//         if (data[props.name][i].language === lng) {
//             data[props.name].splice(i, 1)
//             return
//         }
//     }
// }

let activeTab = ref("en")
let availableLanguages = ["en", "de"]
// if (props.horizontal) {
// init all languages
for (const lng of availableLanguages) {
    if (!schema(lng)) {
        addEntry(lng)
    }
}
// }
</script>

<template>
    <div class="translatable" v-if="!props.horizontal">
        <ul class="nav nav-tabs">
            <li class="nav-item" v-for="lng of availableLanguages">
                <a class="nav-link" :class="{ active: activeTab === lng }" @click="changeTab(lng)">{{ lng }}</a>
            </li>
        </ul>
        <div class="translatable-tab-body ${showShadow ? 'with-shadow-element' : ''}">
            <template v-for="lng of availableLanguages">
                <div v-show="activeTab === lng">
                    <template v-if="schema(lng)">
                        <VfFormRow :schema="schema(lng)" :data="data"></VfFormRow>
                        <!--                        <button type="button" @click="delEntry(activeTab)" class="btn btn-danger" v-if="lng !== 'en'">-->
                        <!--                            <i class="fas fa-trash"></i>-->
                        <!--                            <span t="@translation:form.del"></span>-->
                        <!--                        </button>-->
                    </template>
                    <template v-else>
                        <div class="missing">
                            <button class="btn btn-light" @click="addEntry(activeTab)" type="button">
                                <i class="fas fa-plus-square fa-3x"></i>
                                <br />
                                <span t="@translation:form.add"></span>
                            </button>
                        </div>
                    </template>
                </div>
            </template>
        </div>
    </div>
    <div class="row" v-else>
        <div class="col-6" v-for="lng of availableLanguages">
            <div class="text-center">
                {{ lng }}
            </div>
            <VfFormRow :schema="schema(lng)" :data="data"></VfFormRow>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.translatable {
    .nav-tabs .nav-item .tab-has-errors {
        background-color: red;
    }

    .translatable-tab-body {
        display: grid;
        align-items: stretch;

        > div {
            grid-column: 1;
            grid-row: 1;
        }

        .missing {
            background-color: rgba(0, 0, 0, 0.3);
            display: flex;
            align-items: center;
            justify-content: center;
            z-index: 1;
        }
    }
}
</style>
