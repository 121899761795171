<script setup lang="ts">
type _VTI_TYPE_DebitorProfileType = "debitor" | "creditor"
type _VTI_TYPE_ReviewStateEnum = "created" | "edited" | "clean"
interface _VTI_TYPE_AccountContact {
    /**
     * Groups: Default, default, list, appConfig, select, id
     */
    id?: string
    /**
     * Groups: default, list, Address
     */
    addressLine?: string
    /**
     * Groups: default, list, Address
     */
    addressLine2?: string
    /**
     * Groups: default, list, AddressTrait.street, Address
     */
    street?: string
    /**
     * Groups: default, list, AddressTrait.streetNumber, Address
     */
    streetNumber?: string
    /**
     * Groups: default, list, AddressTrait.zip, Address
     */
    zip?: string
    /**
     * Groups: default, list, AddressTrait.city, Address
     */
    city?: string
    /**
     * Groups: default, list, AddressTrait.country, Address
     */
    country?: string
    /**
     * Groups: default, list
     */
    type?: string
    /**
     * Groups: default, list
     */
    primary?: boolean
    /**
     * Groups: default, list
     */
    locked?: boolean
    /**
     * Groups: default, list
     */
    notice?: string
    /**
     * Groups: default, list
     */
    value?: string
    /**
     * Groups: default, list
     */
    iban?: string
    /**
     * Groups: default, list
     */
    bic?: string
    /**
     * Groups: default, list
     */
    accountOwner?: string
    /**
     * Groups: default, list
     */
    bankName?: string
    /**
     * Groups: default
     */
    isAssignedToAthlete?: boolean
}
interface _VTI_TYPE_DebitorProfile {
    /**
     * Groups: Default, default, list, appConfig, select, id
     */
    id?: string
    /**
     * Groups: default, list, Address
     */
    addressLine?: string
    /**
     * Groups: default, list, Address
     */
    addressLine2?: string
    /**
     * Groups: default, list, AddressTrait.street, Address
     */
    street?: string
    /**
     * Groups: default, list, AddressTrait.streetNumber, Address
     */
    streetNumber?: string
    /**
     * Groups: default, list, AddressTrait.zip, Address
     */
    zip?: string
    /**
     * Groups: default, list, AddressTrait.city, Address
     */
    city?: string
    /**
     * Groups: default, list, AddressTrait.country, Address
     */
    country?: string
    /**
     * Groups: DebitorProfile, list
     */
    accountNumber?: string
    /**
     * Groups: DebitorProfile, list
     */
    name?: string
    /**
     * Groups: DebitorProfile
     */
    matchCode?: string
    /**
     * Groups: DebitorProfile
     */
    vatId?: string
    /**
     * Groups: DebitorProfile, list
     */
    state?: _VTI_TYPE_ReviewStateEnum
    /**
     * Groups: DebitorProfile, list
     */
    type?: _VTI_TYPE_DebitorProfileType
    /**
     * Groups: DebitorProfile, list
     */
    isPrimary?: boolean
}
interface _VTI_TYPE_Account {
    /**
     * Groups: Default, default, list
     */
    disabled?: boolean
    /**
     * Groups: Default, default, list, appConfig, select, id
     */
    id?: string
    /**
     * Groups: default, appConfig, select, list, myAthletes, dashboard-invitation, selection-invitation, Account.name
     */
    name?: string
    /**
     * Groups: Account.deletedAt
     */
    deletedAt?: any
    /**
     * Groups: passportlist
     */
    parent?: Account
    /**
     * Groups: default, Account.createdAt
     */
    createdAt?: string /* DateTime */
    /**
     * Groups: Account.debitorProfiles
     */
    debitorProfiles?: any[] | any
    /**
     * Groups: Account.primaryDebitorProfile
     */
    primaryDebitorProfile?: _VTI_TYPE_DebitorProfile
    /**
     * Groups: Default, appConfig, list
     */
    isAdmin?: boolean
    /**
     * Groups: Default, appConfig, list
     */
    isUser?: boolean
    /**
     * Groups: Default, default, appConfig, list
     */
    type?: string
    /**
     * Groups: Account.primaryRegistrationAddress
     */
    primaryRegistrationAddress?: _VTI_TYPE_AccountContact
    /**
     * Groups: admin-list
     */
    listContact?: _VTI_TYPE_AccountContact
    /**
     * Groups: admin-list
     */
    addresses?: any[] | any
    /**
     * Groups: Account.primaryAccountNumber
     */
    primaryAccountNumber?: string
}
type _VTI_TYPE_IssueType = "OrganizationCreation" | "EventRegistration" | "Order" | "ComThread" | "AuditAthleteUpload" | "AccountInvitation" | "OrganizationDebitorProfile" | "StaffInvitation" | "AddressUpdate"
type _VTI_TYPE_IssueState = "open" | "done"
interface _VTI_TYPE_Issue {
    /**
     * Groups: Default, default, list, appConfig, select, id
     */
    id?: string
    /**
     * Groups: default, list
     */
    name?: string
    /**
     * Groups: default, list
     */
    nameProps?: any[] | any
    /**
     * Groups: default, list
     */
    state?: _VTI_TYPE_IssueState
    /**
     * Groups: default, list
     */
    type?: _VTI_TYPE_IssueType
    /**
     * Groups: default, list
     */
    createdAt?: string /* DateTime */
    /**
     * Groups: Issue.list, Issue.owner
     */
    owner?: _VTI_TYPE_Account
    /**
     * Groups: default, list
     */
    lastTaskAt?: string /* DateTime */
    /**
     * Groups: default, list
     */
    taskCount?: number
    /**
     * Groups: default, list
     */
    taskWithoutMessagesCount?: number
    /**
     * Groups: list
     */
    openTaskCount?: number
    /**
     * Groups: list
     */
    openTaskWithoutMessagesCount?: number
    /**
     * Groups: list
     */
    messageCount?: number
    /**
     * Groups: tasks
     */
    tasks?: Task[]
    /**
     * Groups: Issue.issueGroupTree
     */
    issueGroupTree?: IssueGroup[]
    /**
     * Groups: Issue.productGroupTree
     */
    productGroupTree?: ProductGroup[]
    /**
     * Groups: default, list
     */
    closedAt?: string /* DateTime */
}
interface TasksProps {
    task: _VTI_TYPE_T
    issue: _VTI_TYPE_Issue
    viewMode: "customer" | "admin"
    index: number
}
import { useLegacyHttpClient } from '@/composables/compat/useLegacyHttpClient'
import { AuditAthleteUploadTask } from '@/model/app/audit-athlete-upload-task'
import { renderConnectionStatus } from '@/pages/App/OrganizationMember/renderConnectionStatus'
import { $date, sleep, useApp } from '@/vf'
import { computed, reactive, ref, toRefs } from 'vue'
const props = defineProps<TasksProps<AuditAthleteUploadTask>>()
const emit = defineEmits<{
    (e: "updated"): void
}>()
const { task } = toRefs(props)
const { apiLink } = useApp()
const http = useLegacyHttpClient()
// const inlineConfirm = createInlineConfirm({
//     action() {
//         console.log(props)
//         return http.post(`/organization/${props.issue.owner.id}/member/approve-agreement/${task.value.id}`, {
//             documentPageAssignment: task.value.documentPageAssignment,
//             ...newStatus,
//         })
//     },
//     afterAction() {
//         emit("updated")
//     },
// })
// const inlineReject = createInlineConfirm({
//     action() {
//         console.log(props)
//         return http.post(`/organization/${props.issue.owner.id}/member/reject-agreement/${task.value.id}`, {})
//     },
//     afterAction() {
//         emit("updated")
//     },
// })
const updateErrors = ref([])
async function save() {
    const errors = []
    if (props.task.updatePersonStatus && newStatus.personStatus == "ignore") {
        errors.push("")
    }
    if (props.task.updatePrivacy && newStatus.privacy == "ignore") {
        errors.push("privacy")
    }
    if (props.task.updatePictureApproval && newStatus.picture == "ignore") {
        errors.push("picture")
    }
    // if (props.task.updateSafeguarding && newStatus.safeguarding == "ignore") {
    //     errors.push("safeguarding")
    // }
    if (errors.length > 0) {
        for (let i = 0; i < 3; i++) {
            updateErrors.value = errors
            await sleep(200)
            updateErrors.value = []
            await sleep(200)
        }
        return
    }
    await http.post(`/organization/${props.issue.owner.id}/member/approve-agreement/${task.value.id}`, {
        documentPageAssignment: task.value.documentPageAssignment,
        ...newStatus,
    })
    emit("updated")
}
const uploadedFile = computed(() => props.task.attachments.find(a => a.originalFilename == "uploaded-file.pdf"))
function nextStatus(currentStatus: string, statusAfterIgnore: string = "set") {
    switch (currentStatus) {
        case "set":
            return "unset"
        case "unset":
        case "deactivate":
            return "ignore"
        case "activate":
            return "deactivate"
        default:
            return statusAfterIgnore
    }
}
const newStatus = reactive({
    personStatus: "ignore",
    picture: "ignore",
    privacy: "ignore",
    safeguarding: "ignore",
})
if (task.value.updatePersonData) {
    newStatus.picture = "unset"
    newStatus.privacy = "unset"
}
</script>

<template>
    <div class="p-4 bg-white">
        <div class="row">
            <div class="col-md-6 align-self-stretch">
                <iframe
                    v-if="uploadedFile"
                    width="100%"
                    height="100%"
                    style="min-height: 500px"
                    :src="apiLink('/tasks/task/' + task.id + '/attachment/' + uploadedFile.id + '/download')"
                ></iframe>
                <div v-else class="h-100 text-center d-flex justify-content-center flex-column">
                    <div>
                        {{ $t("@tasks:tasks.audit_athlete_upload.no_file") }}
                    </div>
                </div>
            </div>
            <div class="col-md-6">
                <div v-if="viewMode === 'admin' && uploadedFile">
                    <b>{{ $t("@tasks:tasks.audit_athlete_upload.page_assignment") }}</b>
                    <table class="table">
                        <tr>
                            <th>{{ $t("@tasks:tasks.audit_athlete_upload.page_assignment_table.pdfPage") }}</th>
                            <th>{{ $t("@tasks:tasks.audit_athlete_upload.page_assignment_table.qrName") }}</th>
                            <th>{{ $t("@tasks:tasks.audit_athlete_upload.page_assignment_table.qrVersion") }}</th>
                            <th>{{ $t("@tasks:tasks.audit_athlete_upload.page_assignment_table.qrPage") }}</th>
                            <th>{{ $t("@tasks:tasks.audit_athlete_upload.page_assignment_table.assignment") }}</th>
                        </tr>
                        <tr v-for="page of task.documentPageAssignment">
                            <td>{{ page.pdfPage }}</td>
                            <td>{{ page.conditionName }}</td>
                            <td>{{ page.conditionVersion }}</td>
                            <td>{{ page.uuidPage }}</td>
                            <td>
                                <select
                                    class="form-control"
                                    v-model="page.assignment"
                                    :disabled="['closed', 'cancalled'].includes(task.state)"
                                >
                                    <option :value="undefined">Ignorieren</option>
                                    <option value="other">Sonstiges</option>
                                    <option value="General.Athletes">Rahmenvereinbarung</option>
                                    <option value="Athlete.PictureApproval">Fotofreigabe</option>
                                </select>
                                <br />
                                <input
                                    class="form-control"
                                    v-model="page.description"
                                    v-if="page.assignment === 'other'"
                                    placeholder="Beschreibung"
                                />
                            </td>
                        </tr>
                    </table>
                </div>
                <div>
                    <b>{{ $t("@tasks:tasks.audit_athlete_upload.member_data") }}</b>
                    <table class="table">
                        <tr v-if="task.updatePersonData">
                            <td></td>
                            <td>
                                <b>{{ $t("@tasks:tasks.audit_athlete_upload.personData.old") }}</b>
                            </td>
                            <td>
                                <b>{{ $t("@tasks:tasks.audit_athlete_upload.personData.new") }}</b>
                            </td>
                        </tr>
                        <tr>
                            <td>{{ $t("@tasks:tasks.audit_athlete_upload.firstName") }}</td>
                            <td>{{ task.organizationMember.firstName }}</td>
                            <td
                                v-if="task.updatePersonData"
                                :class="{
                                    'text-danger':
                                        task.organizationMember.firstName != task.changedPersonData.firstName,
                                }"
                            >
                                {{ task.changedPersonData.firstName }}
                            </td>
                        </tr>
                        <tr>
                            <td>{{ $t("@tasks:tasks.audit_athlete_upload.lastName") }}</td>
                            <td>{{ task.organizationMember.lastName }}</td>
                            <td
                                v-if="task.updatePersonData"
                                :class="{
                                    'text-danger': task.organizationMember.lastName != task.changedPersonData.lastName,
                                }"
                            >
                                {{ task.changedPersonData.lastName }}
                            </td>
                        </tr>
                        <tr>
                            <td>{{ $t("@tasks:tasks.audit_athlete_upload.gender") }}</td>
                            <td>{{ task.organizationMember.gender == "m" ? "male" : "female" }}</td>
                            <td
                                v-if="task.updatePersonData"
                                :class="{
                                    'text-danger': task.organizationMember.gender != task.changedPersonData.gender,
                                }"
                            >
                                {{ task.changedPersonData.gender == "m" ? "male" : "female" }}
                            </td>
                        </tr>
                        <tr>
                            <td>{{ $t("@tasks:tasks.audit_athlete_upload.birthDate") }}</td>
                            <td>{{ $date(task.organizationMember.birthDate) }}</td>
                            <td
                                v-if="task.updatePersonData"
                                :class="{
                                    'text-danger':
                                        $date(task.organizationMember.birthDate) !=
                                        $date(task.changedPersonData.birthDate),
                                }"
                            >
                                {{ $date(task.changedPersonData.birthDate) }}
                            </td>
                        </tr>
                    </table>
                </div>
                <div class="my-3">
                    <b>{{ $t("@tasks:tasks.audit_athlete_upload.connections") }}</b>
                    <br />
                    <component :is="() => renderConnectionStatus(task.organizationMember, $t)"></component>
                </div>
                <b>{{ $t("@tasks:tasks.audit_athlete_upload.comment") }}</b>
                <div class="bg-light border-primary border-bottom p-3">
                    {{ task.comment || "-" }}
                </div>
                <div v-if="viewMode === 'admin'" class="mt-3">
                    <b>{{ $t("@tasks:tasks.audit_athlete_upload.actions") }}</b>
                    <table class="my-3">
                        <tr>
                            <th></th>
                            <th>{{ $t("@tasks:tasks.audit_athlete_upload.current_status") }}</th>
                            <th>{{ $t("@tasks:tasks.audit_athlete_upload.requested_status") }}</th>
                            <th v-if="task.state === 'open'">
                                {{ $t("@tasks:tasks.audit_athlete_upload.set_status") }}
                            </th>
                            <th v-else>{{ $t("@tasks:tasks.audit_athlete_upload.new_status") }}</th>
                        </tr>
                        <tr class="text-center">
                            <td class="text-left">{{ $t("@tasks:tasks.audit_athlete_upload.person_status") }}</td>
                            <td>
                                <i
                                    :class="{
                                        'doc-icon-status-active': task.organizationMember.activeState == 'active',
                                        'doc-icon-status-inactive': task.organizationMember.activeState != 'active',
                                    }"
                                ></i>
                            </td>
                            <td>
                                <i
                                    :class="{
                                        'doc-icon-status-active': task.updatePersonStatus == 'activate',
                                        'doc-icon-status-inactive': task.updatePersonStatus == 'deactivate',
                                        'doc-icon-status-none': !task.updatePersonStatus,
                                    }"
                                ></i>
                            </td>
                            <td v-if="task.state === 'open'">
                                <div
                                    class="border border-light d-inline-block px-1"
                                    :class="{ 'bg-danger': updateErrors.includes('personStatus') }"
                                    @click="newStatus.personStatus = nextStatus(newStatus.personStatus, 'activate')"
                                >
                                    <i
                                        :class="{
                                            'doc-icon-status-active': newStatus.personStatus == 'activate',
                                            'doc-icon-status-inactive': newStatus.personStatus == 'deactivate',
                                            'doc-icon-status-none': newStatus.personStatus == 'ignore',
                                        }"
                                    ></i>
                                </div>
                            </td>
                            <td v-else>
                                <i
                                    :class="{
                                        'doc-icon-status-active': task.updatePersonStatusPerformed == 'activate',
                                        'doc-icon-status-inactive': task.updatePersonStatusPerformed == 'deactivate',
                                        'doc-icon-status-none': task.updatePersonStatusPerformed == 'ignore',
                                    }"
                                ></i>
                            </td>
                        </tr>
                        <tr class="text-center">
                            <td class="text-left">{{ $t("@tasks:tasks.audit_athlete_upload.privacy") }}</td>
                            <td>
                                <i
                                    :class="{
                                        'doc-icon-status-active': task.organizationMember.privacyApproved,
                                        'doc-icon-status-inactive': !task.organizationMember.privacyApproved,
                                    }"
                                ></i>
                            </td>
                            <td>
                                <i
                                    :class="{
                                        'doc-icon-status-active': task.updatePrivacy == 'update',
                                        'doc-icon-status-inactive': task.updatePrivacy == 'revoke',
                                        'doc-icon-status-none': !task.updatePrivacy,
                                    }"
                                ></i>
                            </td>
                            <td v-if="task.state === 'open'">
                                <div
                                    class="border border-light d-inline-block px-1"
                                    :class="{ 'bg-danger': updateErrors.includes('privacy') }"
                                    @click="newStatus.privacy = nextStatus(newStatus.privacy)"
                                >
                                    <i
                                        :class="{
                                            'doc-icon-status-active': newStatus.privacy == 'set',
                                            'doc-icon-status-inactive': newStatus.privacy == 'unset',
                                            'doc-icon-status-none': newStatus.privacy == 'ignore',
                                        }"
                                    ></i>
                                </div>
                            </td>
                            <td v-else>
                                <i
                                    :class="{
                                        'doc-icon-status-active': task.updatePrivacyPerformed == 'set',
                                        'doc-icon-status-inactive': task.updatePrivacyPerformed == 'unset',
                                        'doc-icon-status-none': task.updatePrivacyPerformed == 'ignore',
                                    }"
                                ></i>
                            </td>
                        </tr>
                        <tr class="text-center">
                            <td class="text-left">{{ $t("@tasks:tasks.audit_athlete_upload.picture") }}</td>
                            <td>
                                <i
                                    :class="{
                                        'doc-icon-status-active':
                                            task.organizationMember.pictureApprovalState == 'approved',
                                        'doc-icon-status-inactive':
                                            task.organizationMember.pictureApprovalState != 'approved',
                                    }"
                                ></i>
                            </td>
                            <td>
                                <i
                                    :class="{
                                        'doc-icon-status-active': task.updatePictureApproval == 'update',
                                        'doc-icon-status-inactive': task.updatePictureApproval == 'revoke',
                                        'doc-icon-status-none': !task.updatePictureApproval,
                                    }"
                                ></i>
                            </td>
                            <td v-if="task.state === 'open'">
                                <div
                                    class="border border-light d-inline-block px-1"
                                    :class="{ 'bg-danger': updateErrors.includes('picture') }"
                                    @click="newStatus.picture = nextStatus(newStatus.picture)"
                                >
                                    <i
                                        :class="{
                                            'doc-icon-status-active': newStatus.picture == 'set',
                                            'doc-icon-status-inactive': newStatus.picture == 'unset',
                                            'doc-icon-status-none': newStatus.picture == 'ignore',
                                        }"
                                    ></i>
                                </div>
                            </td>
                            <td v-else>
                                <i
                                    :class="{
                                        'doc-icon-status-active': task.updatePictureApprovalPerformed == 'set',
                                        'doc-icon-status-inactive': task.updatePictureApprovalPerformed == 'unset',
                                        'doc-icon-status-none': task.updatePictureApprovalPerformed == 'ignore',
                                    }"
                                ></i>
                            </td>
                        </tr>
                        <tr class="text-center">
                            <td class="text-left">Safeguarding</td>
                            <td><i class="fa-solid fa-circle-minus text-light"></i></td>
                            <td><i class="fa-solid fa-circle-minus text-light"></i></td>
                            <td v-if="task.state === 'open'">
                                <div
                                    class="border border-light d-inline-block px-1"
                                    :class="{ 'bg-danger': updateErrors.includes('safeguarding') }"
                                    @click="newStatus.safeguarding = nextStatus(newStatus.safeguarding)"
                                >
                                    <i
                                        :class="{
                                            'doc-icon-status-active': newStatus.safeguarding == 'set',
                                            'doc-icon-status-inactive': newStatus.safeguarding == 'unset',
                                            'doc-icon-status-none': newStatus.safeguarding == 'ignore',
                                        }"
                                    ></i>
                                </div>
                            </td>
                            <td v-else><i class="fa-solid fa-circle-minus text-light"></i></td>
                        </tr>
                    </table>
                    <div class="text-right" v-if="task.state === 'open'">
                        <button class="btn btn-primary" @click="save()">
                            {{ $t("@tasks:tasks.audit_athlete_upload.save") }}
                        </button>
                    </div>
                    <!--                    <VarsityInlineConfirmGroup>-->
                    <!--                        <div class="text-right">-->
                    <!--                            <VarsityInlineConfirmButton-->
                    <!--                                :controller="inlineReject"-->
                    <!--                                class="btn btn-danger btn-shape-skewed"-->
                    <!--                            >-->
                    <!--                                {{ $t("@tasks:tasks.audit_athlete_upload.reject") }}-->
                    <!--                            </VarsityInlineConfirmButton>-->
                    <!--                            <VarsityInlineConfirmButton-->
                    <!--                                :controller="inlineConfirm"-->
                    <!--                                class="btn btn-primary btn-shape-skewed"-->
                    <!--                            >-->
                    <!--                                {{ $t("@tasks:tasks.audit_athlete_upload.approve") }}-->
                    <!--                            </VarsityInlineConfirmButton>-->
                    <!--                        </div>-->

                    <!--                        <VarsityInlineConfirm :controller="inlineConfirm">-->
                    <!--                            <template #confirmation>-->
                    <!--                                {{ $t("@tasks:tasks.audit_athlete_upload.approve_confirm") }}-->
                    <!--                            </template>-->
                    <!--                            <template #success>-->
                    <!--                                {{ $t("@tasks:tasks.audit_athlete_upload.approve_successfull") }}-->
                    <!--                            </template>-->
                    <!--                        </VarsityInlineConfirm>-->

                    <!--                        <VarsityInlineConfirm :controller="inlineReject" :danger="true">-->
                    <!--                            <template #confirmation>-->
                    <!--                                {{ $t("@tasks:tasks.audit_athlete_upload.reject_confirm") }}-->
                    <!--                            </template>-->
                    <!--                            <template #success>-->
                    <!--                                {{ $t("@tasks:tasks.audit_athlete_upload.reject_successful") }}-->
                    <!--                            </template>-->
                    <!--                        </VarsityInlineConfirm>-->
                    <!--                    </VarsityInlineConfirmGroup>-->
                </div>
            </div>
        </div>
    </div>
</template>