<script setup lang="ts">
import { useApp } from "@/vf"
import { ref } from "vue"
import VuePdfEmbed from "vue-pdf-embed"

const props = defineProps<{
    element: any
}>()

// functions

const { apiLink } = useApp()

const pagesTotal = ref(0)
const page = ref(1)

function pdfLoaded({ numPages }) {
    pagesTotal.value = numPages
}
</script>

<template>
    <div class="ce-pdf">
        <div class="ce-pdf__content">
            <VuePdfEmbed :source="apiLink('/media/' + element.fileName)" :page="page" @loaded="pdfLoaded" />
        </div>
        <div class="ce-pdf__footer mt-4" v-if="pagesTotal > 1">
            <ul class="pagination justify-content-center">
                <li class="page-item" :class="{ disabled: page === 1 }">
                    <a class="page-link" :title="$t('crud:list.first_page')" @click.prevent="page = 1">
                        <i class="fa fa-fw fa-fast-backward"></i>
                    </a>
                </li>
                <li class="page-item" :class="{ disabled: page <= 1 }">
                    <a class="page-link" :title="$t('crud:list.prev_page')" @click.prevent="page--">
                        <i class="fa fa-fw fa-backward"></i>
                    </a>
                </li>
                <li class="page-item" v-for="index in pagesTotal" :key="index" :class="{ active: index == page }">
                    <a class="page-link" @click.prevent="page = index">{{ index }}</a>
                </li>
                <li class="page-item" :class="{ disabled: page >= pagesTotal }">
                    <a class="page-link" :title="$t('crud:list.next_page')" @click.prevent="page++">
                        <i class="fa fa-fw fa-forward"></i>
                    </a>
                </li>
                <li class="page-item" :class="{ disabled: page === pagesTotal }">
                    <a class="page-link" :title="$t('crud:list.last_page')" @click.prevent="page = pagesTotal">
                        <i class="fa fa-fw fa-fast-forward"></i>
                    </a>
                </li>
            </ul>
        </div>
    </div>
</template>
