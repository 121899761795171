import { useLocale } from "@/composables/useLocale"
import { reconfigureRoutes } from "@/router/reconfigureRoutes"
import VfFormCollection from "@/vf/components/crud/form/VfFormCollection.vue"
import { useAppConfig, type AppConfig } from "@/vf/composables"
import { registerVfPlugins } from "@/vf/plugins"
import { plugin as formkit } from "@formkit/vue"
import * as Sentry from "@sentry/vue"
import { createPinia } from "pinia"
import { createApp, watch } from "vue"
import App from "./App.vue"
import VarsityContentProcessor from "./components/VarsityContentProcessor.vue"
import VarsityHintBox from "./components/VarsityHintBox.vue"
import VarsityFeedbackLoading from "./components/feedback/VarsityFeedbackLoading.vue"
import CheckboxToggle from "./components/form/CheckboxToggle.vue"
import TranslationsForm from "./components/form/TranslationsForm.vue"
import { registerYouTubePlaceholder } from "./components/quill/directives"
import createFormkitConfig from "./formkit.config"
import { registerI18n } from "./plugins/i18n"
import router from "./router"

const app = createApp(App)

const pinia = createPinia()

app.use(pinia)

registerVfPlugins(app)
registerYouTubePlaceholder(app)

Sentry.init({
    app,
    dsn: "https://28deca79df8b382b19c75680f8df9ece@o4506948613898240.ingest.us.sentry.io/4507220044546048",
    release: "varsity-web@" + import.meta.env.VITE_SENTRY_RELEASE,
    integrations: [Sentry.replayIntegration()],
    environment: import.meta.env.VITE_ENVIRONMENT ?? "dev",
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
})

function applySentryUser(appConfig: AppConfig) {
    const user = appConfig.login
        ? {
              email: appConfig.login.email,
              username: appConfig.login.username,
              id: appConfig.login.id,
          }
        : null

    console.log("Sentry user", user)
    Sentry.setUser(user)
}

useAppConfig()
    .loadInitialAppConfig(router)
    .then(async appConfig => {
        watch(appConfig, newAppConfig => {
            reconfigureRoutes(router, newAppConfig.routes)
            applySentryUser(newAppConfig)
        })
        reconfigureRoutes(router, appConfig.value.routes)
        applySentryUser(appConfig.value)
        app.use(router)
        ;(window as any).router = router
        ;(window as any).vue = app
        const { changeLocale, locale: localStorageLocale } = useLocale()
        const locale = appConfig.value?.login?.locale ?? localStorageLocale.value
        const i18n = await registerI18n(app, locale)
        changeLocale(locale)

        app.use(formkit, createFormkitConfig(i18n))

        app.component("VarsityContentProcessor", VarsityContentProcessor)
        app.component("VarsityFeedbackLoading", VarsityFeedbackLoading)
        app.component("CheckboxToggle", CheckboxToggle)
        app.component("VarsityHintBox", VarsityHintBox)
        app.component("TranslationsForm", TranslationsForm)
        app.component("VfFormCollection", VfFormCollection)
        app.mount("#app")
    })
