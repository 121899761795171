<script setup lang="ts">
type _VTI_TYPE_DebitorProfileType = "debitor" | "creditor"
type _VTI_TYPE_ReviewStateEnum = "created" | "edited" | "clean"
interface _VTI_TYPE_AccountContact {
    /**
     * Groups: Default, default, list, appConfig, select, id
     */
    id?: string
    /**
     * Groups: default, list, Address
     */
    addressLine?: string
    /**
     * Groups: default, list, Address
     */
    addressLine2?: string
    /**
     * Groups: default, list, AddressTrait.street, Address
     */
    street?: string
    /**
     * Groups: default, list, AddressTrait.streetNumber, Address
     */
    streetNumber?: string
    /**
     * Groups: default, list, AddressTrait.zip, Address
     */
    zip?: string
    /**
     * Groups: default, list, AddressTrait.city, Address
     */
    city?: string
    /**
     * Groups: default, list, AddressTrait.country, Address
     */
    country?: string
    /**
     * Groups: default, list
     */
    type?: string
    /**
     * Groups: default, list
     */
    primary?: boolean
    /**
     * Groups: default, list
     */
    locked?: boolean
    /**
     * Groups: default, list
     */
    notice?: string
    /**
     * Groups: default, list
     */
    value?: string
    /**
     * Groups: default, list
     */
    iban?: string
    /**
     * Groups: default, list
     */
    bic?: string
    /**
     * Groups: default, list
     */
    accountOwner?: string
    /**
     * Groups: default, list
     */
    bankName?: string
    /**
     * Groups: default
     */
    isAssignedToAthlete?: boolean
}
interface _VTI_TYPE_DebitorProfile {
    /**
     * Groups: Default, default, list, appConfig, select, id
     */
    id?: string
    /**
     * Groups: default, list, Address
     */
    addressLine?: string
    /**
     * Groups: default, list, Address
     */
    addressLine2?: string
    /**
     * Groups: default, list, AddressTrait.street, Address
     */
    street?: string
    /**
     * Groups: default, list, AddressTrait.streetNumber, Address
     */
    streetNumber?: string
    /**
     * Groups: default, list, AddressTrait.zip, Address
     */
    zip?: string
    /**
     * Groups: default, list, AddressTrait.city, Address
     */
    city?: string
    /**
     * Groups: default, list, AddressTrait.country, Address
     */
    country?: string
    /**
     * Groups: DebitorProfile, list
     */
    accountNumber?: string
    /**
     * Groups: DebitorProfile, list
     */
    name?: string
    /**
     * Groups: DebitorProfile
     */
    matchCode?: string
    /**
     * Groups: DebitorProfile
     */
    vatId?: string
    /**
     * Groups: DebitorProfile, list
     */
    state?: _VTI_TYPE_ReviewStateEnum
    /**
     * Groups: DebitorProfile, list
     */
    type?: _VTI_TYPE_DebitorProfileType
    /**
     * Groups: DebitorProfile, list
     */
    isPrimary?: boolean
}
interface _VTI_TYPE_Account {
    /**
     * Groups: Default, default, list
     */
    disabled?: boolean
    /**
     * Groups: Default, default, list, appConfig, select, id
     */
    id?: string
    /**
     * Groups: default, appConfig, select, list, myAthletes, dashboard-invitation, selection-invitation, Account.name
     */
    name?: string
    /**
     * Groups: Account.deletedAt
     */
    deletedAt?: any
    /**
     * Groups: passportlist
     */
    parent?: Account
    /**
     * Groups: default, Account.createdAt
     */
    createdAt?: string /* DateTime */
    /**
     * Groups: Account.debitorProfiles
     */
    debitorProfiles?: any[] | any
    /**
     * Groups: Account.primaryDebitorProfile
     */
    primaryDebitorProfile?: _VTI_TYPE_DebitorProfile
    /**
     * Groups: Default, appConfig, list
     */
    isAdmin?: boolean
    /**
     * Groups: Default, appConfig, list
     */
    isUser?: boolean
    /**
     * Groups: Default, default, appConfig, list
     */
    type?: string
    /**
     * Groups: Account.primaryRegistrationAddress
     */
    primaryRegistrationAddress?: _VTI_TYPE_AccountContact
    /**
     * Groups: admin-list
     */
    listContact?: _VTI_TYPE_AccountContact
    /**
     * Groups: admin-list
     */
    addresses?: any[] | any
    /**
     * Groups: Account.primaryAccountNumber
     */
    primaryAccountNumber?: string
}
type _VTI_TYPE_IssueType = "OrganizationCreation" | "EventRegistration" | "Order" | "ComThread" | "AuditAthleteUpload" | "AccountInvitation" | "OrganizationDebitorProfile" | "StaffInvitation" | "AddressUpdate"
type _VTI_TYPE_IssueState = "open" | "done"
interface _VTI_TYPE_Issue {
    /**
     * Groups: Default, default, list, appConfig, select, id
     */
    id?: string
    /**
     * Groups: default, list
     */
    name?: string
    /**
     * Groups: default, list
     */
    nameProps?: any[] | any
    /**
     * Groups: default, list
     */
    state?: _VTI_TYPE_IssueState
    /**
     * Groups: default, list
     */
    type?: _VTI_TYPE_IssueType
    /**
     * Groups: default, list
     */
    createdAt?: string /* DateTime */
    /**
     * Groups: Issue.list, Issue.owner
     */
    owner?: _VTI_TYPE_Account
    /**
     * Groups: default, list
     */
    lastTaskAt?: string /* DateTime */
    /**
     * Groups: default, list
     */
    taskCount?: number
    /**
     * Groups: default, list
     */
    taskWithoutMessagesCount?: number
    /**
     * Groups: list
     */
    openTaskCount?: number
    /**
     * Groups: list
     */
    openTaskWithoutMessagesCount?: number
    /**
     * Groups: list
     */
    messageCount?: number
    /**
     * Groups: tasks
     */
    tasks?: Task[]
    /**
     * Groups: Issue.issueGroupTree
     */
    issueGroupTree?: IssueGroup[]
    /**
     * Groups: Issue.productGroupTree
     */
    productGroupTree?: ProductGroup[]
    /**
     * Groups: default, list
     */
    closedAt?: string /* DateTime */
}
interface TasksProps {
    task: _VTI_TYPE_T
    issue: _VTI_TYPE_Issue
    viewMode: "customer" | "admin"
    index: number
}
import VarsityInlineConfirm from '@/components/VarsityInlineConfirm.vue'
import VarsityInlineConfirmButton from '@/components/VarsityInlineConfirmButton.vue'
import VarsityInlineConfirmGroup from '@/components/VarsityInlineConfirmGroup.vue'
import { useLegacyHttpClient } from '@/composables/compat/useLegacyHttpClient'
import { createInlineConfirm } from '@/composables/createInlineConfirm'
import { ApproveOrganizationDebitorProfileTask } from '@/model/app/approve-organization-debitor-profile-task'
import { toRefs } from 'vue'
const props = defineProps<TasksProps<ApproveOrganizationDebitorProfileTask>>()
const emit = defineEmits<{
    (e: "updated"): void
}>()
const { task } = toRefs(props)
const http = useLegacyHttpClient()
const inlineConfirmApprove = createInlineConfirm({
    async action() {
        await http.post(`/organization/${props.issue.owner.id}/debitor-profile/approve-task/${props.task.id}`, {
            approved: true,
        })
    },
    afterAction() {
        emit("updated")
    },
})
const inlineConfirmDecline = createInlineConfirm({
    async action() {
        await http.post(`/organization/${props.issue.owner.id}/debitor-profile/approve-task/${props.task.id}`, {
            approved: false,
        })
    },
    afterAction() {
        emit("updated")
    },
})
</script>

<template>
    <div class="p-4">
        <dl>
            <dt>Name</dt>
            <dd>
                <h6 class="m-0">{{ task.organizationName }}</h6>
            </dd>

            <dt>{{ $t("@tasks:tasks.approve_organization_debitor_profile.accountNumber") }}</dt>
            <dd>{{ task.accountNumber }}</dd>
        </dl>

        <div class="row">
            <div class="col-md-6">
                <dl>
                    <dt>{{ $t("@tasks:tasks.approve_organization_debitor_profile.old_values") }}</dt>
                    <dd v-if="task.approveType === 'edit'">
                        <div>{{ task.oldValues?.name }}</div>
                        <div>{{ task.oldValues?.addressLine }}</div>
                        <div>{{ task.oldValues?.addressLine2 }}</div>
                        <div>{{ task.oldValues?.street }} {{ task.oldValues?.streetNumber }}</div>
                        <div>{{ task.oldValues?.zip }} {{ task.oldValues?.city }}</div>
                        <div>{{ task.oldValues?.country }}</div>
                    </dd>
                    <dd v-else>
                        <div class="py-5 text-center text-muted">
                            {{ $t("@tasks:tasks.approve_organization_debitor_profile.no_old_values") }}
                        </div>
                    </dd>
                </dl>
            </div>
            <div class="col-md-6">
                <dl>
                    <dt>{{ $t("@tasks:tasks.approve_organization_debitor_profile.new_values") }}</dt>
                    <dd>
                        <div>{{ task.newValues?.name }}</div>
                        <div>{{ task.newValues?.addressLine }}</div>
                        <div>{{ task.newValues?.addressLine2 }}</div>
                        <div>{{ task.newValues?.street }} {{ task.newValues?.streetNumber }}</div>
                        <div>{{ task.newValues?.zip }} {{ task.newValues?.city }}</div>
                        <div>{{ task.newValues?.country }}</div>
                    </dd>
                </dl>
            </div>
        </div>

        <div v-if="props.task.state === 'closed'" class="my-4 text-center">
            {{ $t("@tasks:tasks.approve_organization_debitor_profile.task_approved") }}
        </div>

        <div v-if="props.task.state === 'rejected'" class="my-4 text-center">
            {{ $t("@tasks:tasks.approve_organization_debitor_profile.task_rejected") }}
        </div>

        <div v-if="viewMode === 'admin' && task.state === 'open'" class="mt-4">
            <VarsityInlineConfirmGroup>
                <VarsityInlineConfirmButton class="btn btn-success btn-shape-skewed" :controller="inlineConfirmApprove">
                    {{ $t("@tasks:tasks.approve_organization_debitor_profile.approve") }}
                </VarsityInlineConfirmButton>
                <VarsityInlineConfirmButton class="btn btn-danger btn-shape-skewed" :controller="inlineConfirmDecline">
                    {{ $t("@tasks:tasks.account_invitation.decline") }}
                </VarsityInlineConfirmButton>

                <VarsityInlineConfirm :controller="inlineConfirmApprove">
                    <template #confirmation>
                        {{ $t("@tasks:tasks.approve_organization_debitor_profile.approve_confirm") }}
                    </template>
                    <template #success>
                        {{ $t("@tasks:tasks.approve_organization_debitor_profile.approve_successfull") }}
                    </template>
                </VarsityInlineConfirm>

                <VarsityInlineConfirm :controller="inlineConfirmDecline">
                    <template #confirmation>
                        {{ $t("@tasks:tasks.approve_organization_debitor_profile.decline_confirm") }}
                    </template>
                    <template #success>
                        {{ $t("@tasks:tasks.approve_organization_debitor_profile.decline_successfull") }}
                    </template>
                </VarsityInlineConfirm>
            </VarsityInlineConfirmGroup>
        </div>
    </div>
</template>
