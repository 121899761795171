export function getHref(element) {
    if (!element.config.buttonLink && !element.config.extLink) {
        console.warn("Invalid configuration for getHref(). Expected buttonLink / extLink", element)
        return null
    }

    if (element.config.buttonLink === "_ext") {
        return element.config.extLink
    }

    return element.config.buttonLink
}

export function getTarget(element) {
    return element.config.target ? element.config.target : "_self"
}
