<script lang="ts" setup>
import { findSchemaForName } from "@/vf/utils/SymfonyForm"
import { inject, ref, toRef, type Ref } from "vue"

const props = defineProps<{
    name: string
    path?: string
}>()

const path = props.path ? toRef(props, "path") : inject<Ref<string | undefined>>("vf-form-group", ref(undefined))
const schema = findSchemaForName(props.name, "VfFormLabel", path.value)
</script>

<template>
    <label :class="{ required: !!schema.validation?.includes('required') }">{{ $t(schema.label) }}</label>
</template>
