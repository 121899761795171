<script setup lang="ts">
type _VTI_TYPE_DebitorProfileType = "debitor" | "creditor"
type _VTI_TYPE_ReviewStateEnum = "created" | "edited" | "clean"
interface _VTI_TYPE_AccountContact {
    /**
     * Groups: Default, default, list, appConfig, select, id
     */
    id?: string
    /**
     * Groups: default, list, Address
     */
    addressLine?: string
    /**
     * Groups: default, list, Address
     */
    addressLine2?: string
    /**
     * Groups: default, list, AddressTrait.street, Address
     */
    street?: string
    /**
     * Groups: default, list, AddressTrait.streetNumber, Address
     */
    streetNumber?: string
    /**
     * Groups: default, list, AddressTrait.zip, Address
     */
    zip?: string
    /**
     * Groups: default, list, AddressTrait.city, Address
     */
    city?: string
    /**
     * Groups: default, list, AddressTrait.country, Address
     */
    country?: string
    /**
     * Groups: default, list
     */
    type?: string
    /**
     * Groups: default, list
     */
    primary?: boolean
    /**
     * Groups: default, list
     */
    locked?: boolean
    /**
     * Groups: default, list
     */
    notice?: string
    /**
     * Groups: default, list
     */
    value?: string
    /**
     * Groups: default, list
     */
    iban?: string
    /**
     * Groups: default, list
     */
    bic?: string
    /**
     * Groups: default, list
     */
    accountOwner?: string
    /**
     * Groups: default, list
     */
    bankName?: string
    /**
     * Groups: default
     */
    isAssignedToAthlete?: boolean
}
interface _VTI_TYPE_DebitorProfile {
    /**
     * Groups: Default, default, list, appConfig, select, id
     */
    id?: string
    /**
     * Groups: default, list, Address
     */
    addressLine?: string
    /**
     * Groups: default, list, Address
     */
    addressLine2?: string
    /**
     * Groups: default, list, AddressTrait.street, Address
     */
    street?: string
    /**
     * Groups: default, list, AddressTrait.streetNumber, Address
     */
    streetNumber?: string
    /**
     * Groups: default, list, AddressTrait.zip, Address
     */
    zip?: string
    /**
     * Groups: default, list, AddressTrait.city, Address
     */
    city?: string
    /**
     * Groups: default, list, AddressTrait.country, Address
     */
    country?: string
    /**
     * Groups: DebitorProfile, list
     */
    accountNumber?: string
    /**
     * Groups: DebitorProfile, list
     */
    name?: string
    /**
     * Groups: DebitorProfile
     */
    matchCode?: string
    /**
     * Groups: DebitorProfile
     */
    vatId?: string
    /**
     * Groups: DebitorProfile, list
     */
    state?: _VTI_TYPE_ReviewStateEnum
    /**
     * Groups: DebitorProfile, list
     */
    type?: _VTI_TYPE_DebitorProfileType
    /**
     * Groups: DebitorProfile, list
     */
    isPrimary?: boolean
}
interface _VTI_TYPE_Account {
    /**
     * Groups: Default, default, list
     */
    disabled?: boolean
    /**
     * Groups: Default, default, list, appConfig, select, id
     */
    id?: string
    /**
     * Groups: default, appConfig, select, list, myAthletes, dashboard-invitation, selection-invitation, Account.name
     */
    name?: string
    /**
     * Groups: Account.deletedAt
     */
    deletedAt?: any
    /**
     * Groups: passportlist
     */
    parent?: Account
    /**
     * Groups: default, Account.createdAt
     */
    createdAt?: string /* DateTime */
    /**
     * Groups: Account.debitorProfiles
     */
    debitorProfiles?: any[] | any
    /**
     * Groups: Account.primaryDebitorProfile
     */
    primaryDebitorProfile?: _VTI_TYPE_DebitorProfile
    /**
     * Groups: Default, appConfig, list
     */
    isAdmin?: boolean
    /**
     * Groups: Default, appConfig, list
     */
    isUser?: boolean
    /**
     * Groups: Default, default, appConfig, list
     */
    type?: string
    /**
     * Groups: Account.primaryRegistrationAddress
     */
    primaryRegistrationAddress?: _VTI_TYPE_AccountContact
    /**
     * Groups: admin-list
     */
    listContact?: _VTI_TYPE_AccountContact
    /**
     * Groups: admin-list
     */
    addresses?: any[] | any
    /**
     * Groups: Account.primaryAccountNumber
     */
    primaryAccountNumber?: string
}
type _VTI_TYPE_IssueType = "OrganizationCreation" | "EventRegistration" | "Order" | "ComThread" | "AuditAthleteUpload" | "AccountInvitation" | "OrganizationDebitorProfile" | "StaffInvitation" | "AddressUpdate"
type _VTI_TYPE_IssueState = "open" | "done"
interface _VTI_TYPE_Issue {
    /**
     * Groups: Default, default, list, appConfig, select, id
     */
    id?: string
    /**
     * Groups: default, list
     */
    name?: string
    /**
     * Groups: default, list
     */
    nameProps?: any[] | any
    /**
     * Groups: default, list
     */
    state?: _VTI_TYPE_IssueState
    /**
     * Groups: default, list
     */
    type?: _VTI_TYPE_IssueType
    /**
     * Groups: default, list
     */
    createdAt?: string /* DateTime */
    /**
     * Groups: Issue.list, Issue.owner
     */
    owner?: _VTI_TYPE_Account
    /**
     * Groups: default, list
     */
    lastTaskAt?: string /* DateTime */
    /**
     * Groups: default, list
     */
    taskCount?: number
    /**
     * Groups: default, list
     */
    taskWithoutMessagesCount?: number
    /**
     * Groups: list
     */
    openTaskCount?: number
    /**
     * Groups: list
     */
    openTaskWithoutMessagesCount?: number
    /**
     * Groups: list
     */
    messageCount?: number
    /**
     * Groups: tasks
     */
    tasks?: Task[]
    /**
     * Groups: Issue.issueGroupTree
     */
    issueGroupTree?: IssueGroup[]
    /**
     * Groups: Issue.productGroupTree
     */
    productGroupTree?: ProductGroup[]
    /**
     * Groups: default, list
     */
    closedAt?: string /* DateTime */
}
interface TasksProps {
    task: _VTI_TYPE_T
    issue: _VTI_TYPE_Issue
    viewMode: "customer" | "admin"
    index: number
}
import VarsityInlineConfirm from '@/components/VarsityInlineConfirm.vue'
import VarsityInlineConfirmButton from '@/components/VarsityInlineConfirmButton.vue'
import { createInlineConfirm } from '@/composables/createInlineConfirm'
import { ChampionshipRegistrationSelectTeamTask } from '@/model/championship/championship-registration-select-team-task'
import { useHttpClient, useHttpGet } from '@/vf'
import AnimateIf from '@/vf/components/AnimateIf.vue'
import { ref, toRefs } from 'vue'
import ChampionshipRegistrationEditContactPerson from './ChampionshipRegistrationEditContactPerson.vue'
import ChampionshipRegistrationEditDebitorProfile from './ChampionshipRegistrationEditDebitorProfile.vue'
import ChampionshipRegistrationNewTeamWizard from './ChampionshipRegistrationNewTeamWizard.vue'
import ChampionshipRegistrationTeamList from './ChampionshipRegistrationTeamList.vue'
import { ChampionshipRegistrationSelectTeamData } from './championship-registration'
const props = defineProps<TasksProps<ChampionshipRegistrationSelectTeamTask>>()
const emit = defineEmits<{
    (e: "updated"): void
}>()
const { task } = toRefs(props)
const http = useHttpClient()
const whatToDo = ref<"new_team" | "change_contact_person" | "change_debitor_profile" | null>(null)
/*─────────────────────────────────────┐
│  list                                │
└─────────────────────────────────────*/
const { data, refresh } = await useHttpGet<ChampionshipRegistrationSelectTeamData>(
    `/championship/registration/${task.value.organizationId}/select-team/${task.value.id}`,
)
/*─────────────────────────────────────┐
│  refresh list                        │
└─────────────────────────────────────*/
async function onUpdated() {
    refresh()
    whatToDo.value = null
}
/*─────────────────────────────────────┐
│  finalize                            │
└─────────────────────────────────────*/
async function finalizeRegistration() {
    await http.post(`/championship/registration/${data.value.registrationId}/finalize`)
    emit("updated")
}
/*─────────────────────────────────────┐
│  cancel registration                 │
└─────────────────────────────────────*/
const cancelRegistrationConfirm = createInlineConfirm({
    action: async () => {
        await http.post(`/championship/registration/${data.value.registrationId}/cancel`)
        emit("updated")
    },
    afterAction: () => {
        emit("updated")
    },
})
</script>
<template>
    <div v-if="task.state === 'open'">
        <div class="p-5 form-light" v-if="!data.contactPerson.contactPersonName">
            <b>
                {{ $t("@tasks:tasks.championship_registration.wizard.provide_contact_person_first") }}
            </b>
            <ChampionshipRegistrationEditContactPerson v-bind="{ data }" @updated="onUpdated" />
        </div>
        <div class="p-5 form-light" v-else-if="!data.debitorProfile">
            <b>
                {{ $t("@tasks:tasks.championship_registration.wizard.provide_debitor_profile_first") }}
            </b>
            <ChampionshipRegistrationEditDebitorProfile v-bind="{ data }" @updated="onUpdated" />
        </div>
        <div class="p-5 form-light" v-else>
            <b>{{ $t("@tasks:tasks.championship_registration.description.title") }}</b>
            <div class="mb-4">{{ $t("@tasks:tasks.championship_registration.description.text") }}</div>

            <div class="my-5 pb-3">
                <b class="d-block mt-4">{{ $t("@tasks:tasks.championship_registration.wizard.what_to_do") }}</b>

                <button
                    type="button"
                    class="wizard-button"
                    @click="whatToDo = 'new_team'"
                    :class="{ dark: whatToDo === 'new_team' }"
                >
                    {{ $t("@tasks:tasks.championship_registration.wizard.register_team") }}
                </button>

                <AnimateIf :if="whatToDo === 'new_team'">
                    <ChampionshipRegistrationNewTeamWizard v-bind="{ data }" @saved="onUpdated" />
                </AnimateIf>

                <button
                    type="button"
                    class="wizard-button"
                    @click="whatToDo = 'change_contact_person'"
                    :class="{ dark: whatToDo === 'change_contact_person' }"
                >
                    {{ $t("@tasks:tasks.championship_registration.wizard.change_contact_person") }}
                </button>

                <AnimateIf :if="whatToDo === 'change_contact_person'">
                    <ChampionshipRegistrationEditContactPerson v-bind="{ data }" @updated="onUpdated" />
                </AnimateIf>

                <button
                    type="button"
                    class="wizard-button"
                    @click="whatToDo = 'change_debitor_profile'"
                    :class="{ dark: whatToDo === 'change_debitor_profile' }"
                >
                    {{ $t("@tasks:tasks.championship_registration.wizard.change_debitor_profile") }}
                </button>

                <AnimateIf :if="whatToDo === 'change_debitor_profile'">
                    <div class="p-5 form-light">
                        <b>
                            {{ $t("@tasks:tasks.championship_registration.wizard.please_provide_new_debitor_profile") }}
                        </b>
                        <ChampionshipRegistrationEditDebitorProfile v-bind="{ data }" @updated="onUpdated" />
                    </div>
                </AnimateIf>

                <button
                    class="wizard-button"
                    type="button"
                    v-if="data.teams.length > 0"
                    @click="finalizeRegistration()"
                >
                    {{ $t("@tasks:tasks.championship_registration.wizard.finalize_registration") }}
                </button>
            </div>

            <b class="mt-5 d-block">
                {{ $t("@tasks:tasks.championship_registration.contact_person.text", data.contactPerson) }}
            </b>
            <b class="my-2 d-block">
                {{ $t("@tasks:tasks.championship_registration.debitor_profile.text", { ...data.debitorProfile }) }}
            </b>

            <ChampionshipRegistrationTeamList
                :teams="data.teams"
                :show-price="false"
                :show-edit="true"
                :show-delete="true"
                :data="data"
                :view-mode="props.viewMode"
                @updated="refresh()"
            />

            <div class="d-flex justify-content-between mt-5">
                <VarsityInlineConfirmButton :controller="cancelRegistrationConfirm">
                    {{ $t("@tasks:tasks.championship_registration.wizard.cancel_registration.button") }}
                </VarsityInlineConfirmButton>

                <button
                    class="btn btn-primary"
                    type="button"
                    :disabled="data.teams.length === 0"
                    @click="finalizeRegistration()"
                >
                    {{ $t("@tasks:tasks.championship_registration.wizard.finalize_registration") }}
                </button>
            </div>

            <VarsityInlineConfirm :controller="cancelRegistrationConfirm">
                <template #confirmation>
                    {{ $t("@tasks:tasks.championship_registration.wizard.cancel_registration.confirmation") }}
                </template>
                <template #success>
                    {{ $t("@tasks:tasks.championship_registration.wizard.cancel_registration.success") }}
                </template>
            </VarsityInlineConfirm>
        </div>
    </div>
</template>
