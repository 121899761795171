<script setup lang="ts">
import type { Championship } from "@/model/championship/championship"
import type { ChampionshipBid } from "@/model/championship/championship-bid"
import { useHttpGet } from "@/vf"
import { debouncedRef } from "@vueuse/core"
import { computed, reactive, ref } from "vue"
import CmsEventCard from "./Championship/CmsEventCard.vue"

const props = defineProps<{
    element: any
}>()

const filter = reactive({
    dateFrom: "",
    dateUntil: "",
    bid: "",
})

type SortFields = "brand" | "name" | "location" | "city" | "dateFrom" | "dateUntil"
const sortBy = ref<SortFields>("dateFrom")
const sortDirection = ref<"asc" | "desc">("asc")

const url = computed(() => {
    const query = new URLSearchParams()

    if (filter.dateFrom) {
        query.set("dateFrom", filter.dateFrom)
    }

    if (filter.dateUntil) {
        query.set("dateUntil", filter.dateUntil)
    }

    if (filter.bid) {
        query.set("bid", filter.bid)
    }

    query.set("sortBy", sortBy.value)
    query.set("sortDirection", sortDirection.value)
    if (props.element.config.selectedChampionships) {
        query.set("championships", props.element.config.selectedChampionships.join(","))
    }

    return "/championship/registration/" + (query.toString() ? "?" + query.toString() : "")
})

const { data: events, refresh } = await useHttpGet<Championship[]>(debouncedRef(url, 300))

const { data: bids } = await useHttpGet<ChampionshipBid[]>("championship/bid/")

function sort(field: SortFields) {
    if (sortBy.value === field) {
        sortDirection.value = sortDirection.value === "asc" ? "desc" : "asc"
    } else {
        sortBy.value = field
        sortDirection.value = "asc"
    }
}
</script>

<template>
    <div class="w-100">
        <form class="event-filters d-none d-lg-block" @submit.prevent="refresh()">
            <div class="row align-items-end">
                <div class="col-3">
                    <b>{{ $t("@championship:registration.event.dateFrom") }}</b>
                    <input type="date" class="form-control" v-model="filter.dateFrom" />
                </div>
                <div class="col-3">
                    <b>{{ $t("@championship:registration.event.dateUntil") }}</b>
                    <input type="date" class="form-control" v-model="filter.dateUntil" />
                </div>
                <div class="col-3">
                    <b>{{ $t("@championship:championship.bids") }}</b>
                    <select class="form-control" v-model="filter.bid">
                        <option></option>
                        <option v-for="bid of bids" :value="bid.id">{{ bid.name }}</option>
                    </select>
                </div>
                <div class="col-3">
                    <button type="submit" class="btn btn-primary mb-0">
                        {{ $t("@championship:registration.event.submitSearch") }}
                    </button>
                </div>
            </div>
        </form>

        <div class="sort-bar mb-4 d-none d-lg-block">
            <div class="row">
                <div class="col-2" :class="sortBy === 'brand' ? ['sort', sortDirection] : []" @click="sort('brand')">
                    {{ $t("@championship:registration.event.brand") }}
                </div>
                <div class="col-2" :class="sortBy === 'name' ? ['sort', sortDirection] : []" @click="sort('name')">
                    {{ $t("@championship:registration.event.name") }}
                </div>
                <div
                    class="col-2"
                    :class="sortBy === 'location' ? ['sort', sortDirection] : []"
                    @click="sort('location')"
                >
                    {{ $t("@championship:registration.event.location") }}
                </div>
                <div class="col-2" :class="sortBy === 'city' ? ['sort', sortDirection] : []" @click="sort('city')">
                    {{ $t("@championship:registration.event.city") }}
                </div>
                <div
                    class="col-4"
                    :class="sortBy === 'dateFrom' ? ['sort', sortDirection] : []"
                    @click="sort('dateFrom')"
                >
                    {{ $t("@championship:registration.date") }}
                </div>
            </div>
        </div>

        <CmsEventCard v-for="event in events" v-bind="{ event }" class="mb-4" />
    </div>
</template>

<style scoped lang="scss">
@import "@/styles/variables";

.event-filters {
    background-color: #eef3f6;
    border-radius: 5px;
    padding: 1.5rem 2rem 2.5rem 1.5rem;
    margin-bottom: 2rem;
}
.sort-bar {
    background-color: var(--primary);
    font-family: "Roboto Slab", sans-serif;
    border-radius: 5px;
    color: #fff;
    font-weight: bold;
    font-size: 1.3rem;
    margin-bottom: 2rem;
}
.sort-bar > div.row > div {
    padding: 1rem 2rem;
    border-right: 1px solid #ffffff50;
    cursor: pointer;
}
.sort-bar > div.row > div:last-child {
    border-right: none;
}

.sort-bar > div.row > div::after {
    font-family: $fa-style-family;
    content: fa-content($fa-var-sort);
    color: #ffffff50;
    position: absolute;
    right: 1rem;
}

.sort-bar > div.row > div:last-child::after {
    right: 2rem;
}

.sort-bar > div.row > div.sort.asc::after {
    content: fa-content($fa-var-sort-up);
    color: #fff;
}

.sort-bar > div.row > div.sort.desc::after {
    content: fa-content($fa-var-sort-down);
    color: #fff;
}
</style>
