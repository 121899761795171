<script lang="ts" setup>
import { getNode, type FormKitMessage } from "@formkit/core"
import { onMounted, ref, watch } from "vue"

const props = defineProps<{
    name: string
}>()
// https://formkit.com/advanced/custom-inputs#schema-inputs
// https://formkit.com/essentials/inputs#sections-schema
const messages = ref<FormKitMessage[]>([])
onMounted(() => {
    const node = getNode(props.name)
    watch(
        () => node?.context?.messages,
        () => {
            messages.value = Object.values(node?.context?.messages ?? {})
        },
    )
})
</script>

<template>
    <div v-for="message of messages" :key="message.key" class="alert alert-danger">
        {{ message.value }}
    </div>
</template>
