<script lang="ts" setup>
import VfFormRow from "./VfFormRow.vue"

const props = defineProps<{name: string}>()
// https://formkit.com/advanced/custom-inputs#schema-inputs
// https://formkit.com/essentials/inputs#sections-schema
const sectionSchema = {
    label: {
        $el: null,
        children: [],
    },
    outer: { $el: null },
    inner: { $el: null },
    wrapper: { $el: null },
    messages: { $el: null, children: [] },
}
</script>

<template>
    <VfFormRow :name="props.name" :sections-schema="sectionSchema"></VfFormRow>
</template>
