<script setup lang="ts">
type _VTI_TYPE_DebitorProfileType = "debitor" | "creditor"
type _VTI_TYPE_ReviewStateEnum = "created" | "edited" | "clean"
interface _VTI_TYPE_AccountContact {
    /**
     * Groups: Default, default, list, appConfig, select, id
     */
    id?: string
    /**
     * Groups: default, list, Address
     */
    addressLine?: string
    /**
     * Groups: default, list, Address
     */
    addressLine2?: string
    /**
     * Groups: default, list, AddressTrait.street, Address
     */
    street?: string
    /**
     * Groups: default, list, AddressTrait.streetNumber, Address
     */
    streetNumber?: string
    /**
     * Groups: default, list, AddressTrait.zip, Address
     */
    zip?: string
    /**
     * Groups: default, list, AddressTrait.city, Address
     */
    city?: string
    /**
     * Groups: default, list, AddressTrait.country, Address
     */
    country?: string
    /**
     * Groups: default, list
     */
    type?: string
    /**
     * Groups: default, list
     */
    primary?: boolean
    /**
     * Groups: default, list
     */
    locked?: boolean
    /**
     * Groups: default, list
     */
    notice?: string
    /**
     * Groups: default, list
     */
    value?: string
    /**
     * Groups: default, list
     */
    iban?: string
    /**
     * Groups: default, list
     */
    bic?: string
    /**
     * Groups: default, list
     */
    accountOwner?: string
    /**
     * Groups: default, list
     */
    bankName?: string
    /**
     * Groups: default
     */
    isAssignedToAthlete?: boolean
}
interface _VTI_TYPE_DebitorProfile {
    /**
     * Groups: Default, default, list, appConfig, select, id
     */
    id?: string
    /**
     * Groups: default, list, Address
     */
    addressLine?: string
    /**
     * Groups: default, list, Address
     */
    addressLine2?: string
    /**
     * Groups: default, list, AddressTrait.street, Address
     */
    street?: string
    /**
     * Groups: default, list, AddressTrait.streetNumber, Address
     */
    streetNumber?: string
    /**
     * Groups: default, list, AddressTrait.zip, Address
     */
    zip?: string
    /**
     * Groups: default, list, AddressTrait.city, Address
     */
    city?: string
    /**
     * Groups: default, list, AddressTrait.country, Address
     */
    country?: string
    /**
     * Groups: DebitorProfile, list
     */
    accountNumber?: string
    /**
     * Groups: DebitorProfile, list
     */
    name?: string
    /**
     * Groups: DebitorProfile
     */
    matchCode?: string
    /**
     * Groups: DebitorProfile
     */
    vatId?: string
    /**
     * Groups: DebitorProfile, list
     */
    state?: _VTI_TYPE_ReviewStateEnum
    /**
     * Groups: DebitorProfile, list
     */
    type?: _VTI_TYPE_DebitorProfileType
    /**
     * Groups: DebitorProfile, list
     */
    isPrimary?: boolean
}
interface _VTI_TYPE_Account {
    /**
     * Groups: Default, default, list
     */
    disabled?: boolean
    /**
     * Groups: Default, default, list, appConfig, select, id
     */
    id?: string
    /**
     * Groups: default, appConfig, select, list, myAthletes, dashboard-invitation, selection-invitation, Account.name
     */
    name?: string
    /**
     * Groups: Account.deletedAt
     */
    deletedAt?: any
    /**
     * Groups: passportlist
     */
    parent?: Account
    /**
     * Groups: default, Account.createdAt
     */
    createdAt?: string /* DateTime */
    /**
     * Groups: Account.debitorProfiles
     */
    debitorProfiles?: any[] | any
    /**
     * Groups: Account.primaryDebitorProfile
     */
    primaryDebitorProfile?: _VTI_TYPE_DebitorProfile
    /**
     * Groups: Default, appConfig, list
     */
    isAdmin?: boolean
    /**
     * Groups: Default, appConfig, list
     */
    isUser?: boolean
    /**
     * Groups: Default, default, appConfig, list
     */
    type?: string
    /**
     * Groups: Account.primaryRegistrationAddress
     */
    primaryRegistrationAddress?: _VTI_TYPE_AccountContact
    /**
     * Groups: admin-list
     */
    listContact?: _VTI_TYPE_AccountContact
    /**
     * Groups: admin-list
     */
    addresses?: any[] | any
    /**
     * Groups: Account.primaryAccountNumber
     */
    primaryAccountNumber?: string
}
type _VTI_TYPE_IssueType = "OrganizationCreation" | "EventRegistration" | "Order" | "ComThread" | "AuditAthleteUpload" | "AccountInvitation" | "OrganizationDebitorProfile" | "StaffInvitation" | "AddressUpdate"
type _VTI_TYPE_IssueState = "open" | "done"
interface _VTI_TYPE_Issue {
    /**
     * Groups: Default, default, list, appConfig, select, id
     */
    id?: string
    /**
     * Groups: default, list
     */
    name?: string
    /**
     * Groups: default, list
     */
    nameProps?: any[] | any
    /**
     * Groups: default, list
     */
    state?: _VTI_TYPE_IssueState
    /**
     * Groups: default, list
     */
    type?: _VTI_TYPE_IssueType
    /**
     * Groups: default, list
     */
    createdAt?: string /* DateTime */
    /**
     * Groups: Issue.list, Issue.owner
     */
    owner?: _VTI_TYPE_Account
    /**
     * Groups: default, list
     */
    lastTaskAt?: string /* DateTime */
    /**
     * Groups: default, list
     */
    taskCount?: number
    /**
     * Groups: default, list
     */
    taskWithoutMessagesCount?: number
    /**
     * Groups: list
     */
    openTaskCount?: number
    /**
     * Groups: list
     */
    openTaskWithoutMessagesCount?: number
    /**
     * Groups: list
     */
    messageCount?: number
    /**
     * Groups: tasks
     */
    tasks?: Task[]
    /**
     * Groups: Issue.issueGroupTree
     */
    issueGroupTree?: IssueGroup[]
    /**
     * Groups: Issue.productGroupTree
     */
    productGroupTree?: ProductGroup[]
    /**
     * Groups: default, list
     */
    closedAt?: string /* DateTime */
}
interface TasksProps {
    task: _VTI_TYPE_T
    issue: _VTI_TYPE_Issue
    viewMode: "customer" | "admin"
    index: number
}
import VarsityInlineConfirm from '@/components/VarsityInlineConfirm.vue'
import VarsityInlineConfirmButton from '@/components/VarsityInlineConfirmButton.vue'
import VarsityInlineConfirmGroup from '@/components/VarsityInlineConfirmGroup.vue'
import { createInlineConfirm } from '@/composables/createInlineConfirm'
import { Invitation } from '@/model/app/invitation'
import ConditionRenderInline from '@/pages/Conditions/Render/ConditionRenderInline.vue'
import { VfFormErrors, VfFormLabel, VfFormRow, VfFormWidget, useAppConfig, useHttpClient, EntityEvent } from '@/vf'
import VfForm from '@/vf/components/crud/form/VfForm.vue'
import { ref } from 'vue'
const props = defineProps<TasksProps<Invitation>>()
interface ExternalModel {
    firstName: string
    lastName: string
    address?: string
}
const emit = defineEmits<{
    (e: "updated"): void
}>()
const http = useHttpClient()
const inlineConfirmAccept = createInlineConfirm({
    async action() {
        addressForm.value?.submit()
        //await http.post(`/invitation/${props.task.token}/accept`, {})
    },
    afterAction() {
        //emit("updated")
    },
})
const inlineConfirmDecline = createInlineConfirm({
    async action() {
        await http.post(`/invitation/public/${props.task.token}/decline`, {})
    },
    afterAction() {
        emit("updated")
    },
})
const { appConfig } = useAppConfig()
// const addresses = (
//     await http.get<ListResponse>("account/" + appConfig.value.account.id + "/contact/?search=&itemsPerPage=-1")
// ).data.list.filter((i: any) => i.type === "address")
const profile = (await http.get<{ firstName: string; lastName: string }>("my/edit-profile")).data
const model = ref<ExternalModel>({
    firstName: profile.firstName,
    lastName: profile.lastName,
})
function save(ev: EntityEvent) {
    ev.entity.firstName = model.value.firstName
    ev.entity.lastName = model.value.lastName
}
async function saved() {
    await refreshAppConfig()
    emit("updated")
}
const addressForm = ref<typeof VfForm>(null)
const { refreshAppConfig } = useAppConfig()
</script>

<template>
    <template v-if="props.task.state == 'open'">
        <form>
            <div class="bg-white p-3">
                {{ $t("@tasks:tasks.staff.top_info_text") }}
                <div class="form-group flex-column mt-4">
                    <div class="flex-grow-1 d-flex">
                        <label class="col-form-label required" for="email">{{ $t("@app:staff.firstName") }}</label>
                        <div class="flex-grow-1">
                            <input class="form-control" type="text" name="firstName" v-model="model.firstName" />
                        </div>
                    </div>
                </div>
                <div class="form-group flex-column">
                    <div class="flex-grow-1 d-flex">
                        <label class="col-form-label required" for="email">{{ $t("@app:staff.lastName") }}</label>
                        <div class="flex-grow-1">
                            <input class="form-control" type="text" name="lastName" v-model="model.lastName" />
                        </div>
                    </div>
                </div>
                <!--            <div class="form-group flex-column">-->
                <!--                <div class="flex-grow-1 d-flex">-->
                <!--                    <label class="col-form-label required" for="email">{{ $t("@app:staff.address") }}</label>-->
                <!--                    <div class="flex-grow-1">-->
                <!--                        <select class="form-control" v-model="model.address">-->
                <!--                            <option :value="null">{{ $t("@app:staff.create_new") }}</option>-->
                <!--                            <option v-for="address in addresses" :value="address.id">-->
                <!--                                {{ address.street }} {{ address.streetNumber }}, {{ address.zipCode }}-->
                <!--                                {{ address.city }}-->
                <!--                            </option>-->
                <!--                        </select>-->
                <!--                    </div>-->
                <!--                </div>-->
                <!--            </div>-->

                <VfForm
                    ref="addressForm"
                    schema-url="/my-staff-member/debitor-profile/schema"
                    :save-url="`/invitation/${props.task.token}/accept-to-admin`"
                    @save="save"
                    @saved="saved"
                >
                    <template #default="{ formModel, schema }">
                        <template v-if="schema.filter(i => i.name === 'copyFromContact').length > 0">
                            <VfFormRow name="copyFromContact"></VfFormRow>

                            <div v-animate-show="formModel.copyFromContact">
                                <VfFormRow name="existingContact"></VfFormRow>
                            </div>
                        </template>

                        <div v-animate-show="!formModel.copyFromContact" style="--col-form-label-width: 100px">
                            <div class="row">
                                <div class="col-md-6">
                                    <VfFormRow name="country"></VfFormRow>
                                </div>
                                <div class="col-md-6">
                                    <VfFormRow name="addressLine2"></VfFormRow>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-md-6">
                                    <VfFormRow name="street"></VfFormRow>
                                </div>
                                <div class="col-md-6">
                                    <VfFormRow name="streetNumber"></VfFormRow>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-md-6">
                                    <div class="form-group flex-column">
                                        <div class="flex-grow-1 align-items-center d-flex">
                                            <VfFormLabel name="zip" class="col-form-label"></VfFormLabel>
                                            <div class="flex-grow-1">
                                                <VfFormWidget name="zip"></VfFormWidget>
                                            </div>
                                        </div>
                                        <VfFormErrors name="zip"></VfFormErrors>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <VfFormRow name="city"></VfFormRow>
                                </div>
                            </div>
                        </div>
                    </template>
                </VfForm>

                <div class="overflow-auto mt-3" style="max-height: min(100vh, 20rem)">
                    <ConditionRenderInline configured-condition="General.Staff"></ConditionRenderInline>
                </div>
            </div>
        </form>
        <div class="mt-4">
            <VarsityInlineConfirmGroup>
                <VarsityInlineConfirmButton class="btn btn-success btn-shape-skewed" :controller="inlineConfirmAccept">
                    {{ $t("@tasks:tasks.account_invitation.accept") }}
                </VarsityInlineConfirmButton>
                <VarsityInlineConfirmButton class="btn btn-danger btn-shape-skewed" :controller="inlineConfirmDecline">
                    {{ $t("@tasks:tasks.account_invitation.decline") }}
                </VarsityInlineConfirmButton>

                <VarsityInlineConfirm :controller="inlineConfirmAccept">
                    <template #confirmation>{{ $t("@tasks:tasks.account_invitation.accept_confirm") }}</template>
                    <template #success>{{ $t("@tasks:tasks.account_invitation.accept_successfull") }}</template>
                </VarsityInlineConfirm>

                <VarsityInlineConfirm :controller="inlineConfirmDecline">
                    <template #confirmation>{{ $t("@tasks:tasks.account_invitation.decline_confirm") }}</template>
                    <template #success>{{ $t("@tasks:tasks.account_invitation.decline_successfull") }}</template>
                </VarsityInlineConfirm>
            </VarsityInlineConfirmGroup>
        </div>
    </template>
</template>