<script setup lang="ts">
import type { CmsPagePart } from "@/model/c-m-s/cms-page-part"
import type { NewsCategoryGroup } from "@/model/c-m-s/news-category-group"
import type { NewsPost } from "@/model/c-m-s/news-post"
import { useHttpGet } from "@/vf"
import { RouterLink } from "vue-router"
import NewsGrid from "../../News/NewsGrid.vue"

const props = defineProps<{
    element: CmsPagePart & { config: { count: number } }
}>()

const { data } = await useHttpGet<{ news: NewsPost[]; categories: NewsCategoryGroup[] }>(
    "/cms/news?limit=" +
        (props.element.config?.count ?? 6) +
        "&" +
        props.element.config?.newsCategories?.map(category => "categories[]=" + encodeURIComponent(category)).join("&"),
    true,
)
const linkToQuery = { categories: props.element.config?.newsCategories.join(",") }
</script>

<template>
    <div>
        <h1 class="text-center related-news-heading">
            <RouterLink :to="{ name: '@Cms.Frontend.news', query: linkToQuery }" class="text-decoration-none">
                {{ $t("@cms:relatedNews.heading") }}
            </RouterLink>
        </h1>
        <NewsGrid :posts="data.news" :per-row="4" centered />
    </div>
</template>

<style scoped>
.related-news-heading a {
    color: #0b6fba;
    display: inline-block;
    margin: 3rem auto;
}
</style>
