<script setup lang="ts">
import { useCookieConsent } from "@/composables/useCookieConsent"
import { useMenuBuilder, type VfMenuItem } from "@/composables/useMenuBuilder"
import { usePostService } from "@/composables/usePostService"
import { useAppState } from "@/stores"
import { useLocaleChooser } from "@/stores/useLocaleChooser"
import { useNavbarState } from "@/stores/useNavbarState"
import { useApp, useAppConfig, useDialogs } from "@/vf"
import AnimateIf from "@/vf/components/AnimateIf.vue"
import { storeToRefs } from "pinia"
import { computed, ref } from "vue"
import { RouterLink, useRouter } from "vue-router"
import { default as VfDropdown } from "../../vf/components/VfDropdown.vue"
import VarsityContentProcessor from "../VarsityContentProcessor.vue"
import VarsityInlineAccountActivation from "./header-cards/VarsityInlineAccountActivation.vue"
import VarsityInlineLogin from "./header-cards/VarsityInlineLogin.vue"
import VarsityInlineLostPassword from "./header-cards/VarsityInlineLostPassword.vue"
import VarsityInlineRegistration from "./header-cards/VarsityInlineRegistration.vue"
import VarsityInlineResetPassword from "./header-cards/VarsityInlineResetPassword.vue"
import VarsityDesktopNavigation from "./VarsityDesktopNavigation.vue"
import VarsityDesktopSecondLevelNavigation from "./VarsityDesktopSecondLevelNavigation.vue"

const router = useRouter()
const localeChooser = useLocaleChooser()
const { currentLocale, showFakeLocale } = storeToRefs(localeChooser)
const { switchLocale, fakeLocale } = localeChooser
const {
    getFirstChildRoutingData,
    onMenuChange,
    menuItems,
    myOfficeRoute,
    myAdminRoute,
    myStaffRoute,
    countTodos,
    getRootMenu,
} = useMenuBuilder()
const { appConfig } = useAppConfig()
const { logout: _logout } = useApp()

const isProduction = import.meta.env.VITE_ENVIRONMENT === "live"

function logout() {
    const appConfig = useAppConfig()

    if (["ChampionshipJudgeTeam", "ChampionshipScoreEvaluator"].includes(appConfig.appConfig.value.account.type)) {
        const postService = usePostService()
        const dialogs = useDialogs()

        if (!postService.isEmpty()) {
            dialogs.error({ message: (window as any).i18n.global.t("@scoring:service.unload_message") })
            return
        }
    }

    _logout()
}

/*─────────────────────────────────────┐
│   help and faq mode                  │
└─────────────────────────────────────*/
const appState = useAppState()
const { faqMode } = storeToRefs(appState)
const { toggleFaqMode } = appState

/*─────────────────────────────────────┐
│   search                             │
└─────────────────────────────────────*/
const searchQuery = ref("")

function submitSearch() {
    const i = { name: "@App.Search.results", query: { query: searchQuery.value } }
    console.log(i)
    router.push(i)
    searchQuery.value = ""
}

/*─────────────────────────────────────┐
│   menu iteraction                    │
└─────────────────────────────────────*/
function menuItemMouseEnter(ev, item) {
    if (item.label !== "@app:nav.my_varsity") {
        showPopup.value = false
        return
    }

    if (appConfig.value.login) {
        showPopup.value = true
    }
}

function clickMyVarsity(item) {
    if (!appConfig.value.login) {
        navbarState.openCard("login")
    } else {
        router.push(getFirstChildRoutingData(item))
    }
}

function popupMouseEnter(ev) {
    if (appConfig.value.login) {
        showPopup.value = true
    }
}

function popupMouseLeave(ev) {
    showPopup.value = false
}

const showPopup = ref(false)
const collapsed = ref(false)

/*─────────────────────────────────────┐
│   sub menus                          │
└─────────────────────────────────────*/
const activeFirstLevelMenu = ref<VfMenuItem | null>(null)
const activeSecondLevelMenu = ref<VfMenuItem | null>(null)
const activeThirdLevelMenu = ref<VfMenuItem | null>(null)
const activeFourthLevelMenu = ref<VfMenuItem | null>(null)

function updateActiveMenus() {
    activeFirstLevelMenu.value = null
    activeSecondLevelMenu.value = null
    activeThirdLevelMenu.value = null
    activeFourthLevelMenu.value = null

    if (menuItems.value) {
        for (const item of menuItems.value) {
            if (item.hasActiveChildren) {
                activeFirstLevelMenu.value = item
            }
        }
    }

    if (activeFirstLevelMenu.value && activeFirstLevelMenu.value.children) {
        for (const item of activeFirstLevelMenu.value.children) {
            if (item.hasActiveChildren) {
                activeSecondLevelMenu.value = item
            }
        }
    }

    if (activeSecondLevelMenu.value && activeSecondLevelMenu.value.children) {
        for (const item of activeSecondLevelMenu.value.children) {
            if (item.hasActiveChildren) {
                activeThirdLevelMenu.value = item
            }
        }
    }

    if (activeThirdLevelMenu.value && activeThirdLevelMenu.value.children) {
        for (const item of activeThirdLevelMenu.value.children) {
            if (item.hasActiveChildren) {
                activeFourthLevelMenu.value = item
            }
        }
    }
}

onMenuChange(updateActiveMenus)

const currentSection = computed(() => {
    return router.currentRoute.value.path.split("/")[1]
})

/*─────────────────────────────────────┐
│   cards (login etc)                  │
└─────────────────────────────────────*/
const navbarState = useNavbarState()
const { openOverlay: openCookieConsentOverlay } = useCookieConsent()
</script>

<template>
    <div>
        <div class="vb-header-container">
            <RouterLink class="vb-header-logo" to="/">
                <img src="/assets/varsity-header.png" />
            </RouterLink>
            <div class="vb-header-content">
                <div class="vb-header">
                    <div class="vb-header-section flex-grow-1 justify-content-end">
                        <div class="flex-grow-1 vb-header-search-input">
                            <input
                                type="search"
                                autocomplete="off"
                                id="global_search"
                                @keypress.enter="submitSearch"
                                v-model="searchQuery"
                            />
                        </div>
                        <label for="global_search" class="c-pointer m-0">
                            <i class="fa fa-fw fa-search"></i>
                            {{ $t("@app:header.search") }}
                        </label>
                    </div>

                    <div class="vb-header-section vb-header-section-dark d-none d-xl-flex">
                        <div class="text-nowrap">
                            <a href="https://www.facebook.com/VarsityEurope" target="_blank" class="text-white mx-3">
                                <i class="fab fa-fw fa-facebook-f"></i>
                            </a>
                            <a href="https://instagram.com/varsityeurope" target="_blank" class="text-white mx-3">
                                <i class="fab fa-fw fa-instagram"></i>
                            </a>
                            <a
                                href="https://www.youtube.com/channel/UCO_58fM3iadJ2RSDwh9-_lA"
                                target="_blank"
                                class="text-white mx-3"
                            >
                                <i class="fab fa-fw fa-youtube"></i>
                            </a>
                        </div>
                    </div>

                    <button
                        class="vb-header-section vb-header-section-dark"
                        @click="toggleFaqMode"
                        :class="{ active: faqMode }"
                        v-if="!isProduction"
                    >
                        <span>{{ $t("@app:header.faq") }}</span>
                    </button>
                    <button class="vb-header-section vb-header-section-dark" @click="openCookieConsentOverlay()">
                        <i class="fa-solid fa-gear"></i>
                    </button>

                    <!-- <button class="vb-header-section" @click="toggleHelpMode" :class="{ active: helpMode }">
    <span>{{ $t("@app:header.help") }}</span>
</button> -->

                    <div class="d-flex">
                        <VfDropdown button-class="d-flex" container-class="d-flex" :menu-style="{ zIndex: 3 }">
                            <template v-slot="{ toggleDropdown }">
                                <div
                                    class="vb-header-section vb-header-section-dark dropdown c-pointer"
                                    @click="toggleDropdown()"
                                >
                                    <div class="d-flex align-items-center flex-nowrap" :title="$t('@app:nav.language')">
                                        <i class="fa fa-fw fa-globe-europe"></i>
                                        <span class="d-none d-md-inline text-uppercase mx-2">
                                            {{ currentLocale }}
                                        </span>
                                        <i class="fa fa-fw fa-chevron-down"></i>
                                    </div>
                                </div>
                            </template>

                            <template #menu="{ closeDropdown }">
                                <div class="dropdown-menu">
                                    <a
                                        class="dropdown-item"
                                        :class="{ active: locale.iso == currentLocale }"
                                        @click="switchLocale(locale.iso, 'en') && closeDropdown()"
                                        v-for="locale in appConfig.languages"
                                        href="#"
                                    >
                                        {{ locale.name }}
                                    </a>
                                    <a
                                        class="dropdown-item"
                                        :class="{ active: fakeLocale == currentLocale }"
                                        @click="switchLocale(fakeLocale, fakeLocale) && closeDropdown()"
                                        href="#"
                                        v-if="showFakeLocale"
                                    >
                                        Language Keys
                                    </a>
                                </div>
                            </template>
                        </VfDropdown>
                        <button
                            class="vb-header-section"
                            @click="logout"
                            v-if="appConfig.account && appConfig.account.type !== 'User'"
                        >
                            <span>Logout</span>
                        </button>
                    </div>
                </div>
                <nav class="navbar navbar-expand-lg navbar-light p-0">
                    <button class="btn btn-dark navbar-toggler" type="button" @click="collapsed = !collapsed">
                        <i class="fa fa-fw fa-bars"></i>
                    </button>

                    <div :class="{ collapse: collapsed }" class="navbar-collapse mr-2">
                        <ul class="navbar-nav mr-5">
                            <VarsityDesktopNavigation :items="menuItems"></VarsityDesktopNavigation>

                            <template v-if="!isProduction || appConfig.login">
                                <li class="flex-grow-1"></li>
                                <template v-for="item of menuItems">
                                    <li
                                        class="nav-item navbar-nav-my-varsity"
                                        :class="{ active: item.hasActiveChildren }"
                                        v-if="item.label === '@app:nav.my_varsity'"
                                        @mouseenter="menuItemMouseEnter($event, item)"
                                    >
                                        <div @click="clickMyVarsity(item)" class="nav-link">
                                            <VarsityContentProcessor
                                                :content="$t(item.label)"
                                            ></VarsityContentProcessor>
                                        </div>
                                    </li>
                                </template>
                            </template>
                        </ul>
                        <div
                            class="navbar-popup"
                            @mouseenter="popupMouseEnter($event)"
                            @mouseleave="popupMouseLeave($event)"
                            v-show="showPopup"
                        >
                            <h1>
                                {{ appConfig.login?.firstName }} {{ appConfig.login?.lastName }} ({{
                                    appConfig.login?.userNumber
                                }})
                            </h1>
                            <hr />

                            <div class="my-2">
                                <RouterLink to="/my-varsity/my-profile">
                                    <template v-if="currentSection !== 'my-varsity'">
                                        {{ $t("@app:nav.switch_to") }}
                                    </template>
                                    My Profile
                                    <span
                                        v-if="countTodos(getRootMenu('my-varsity')).value > 0"
                                        class="position-absolute top-0 start-100 translate-middle badge rounded-pill badge-dark ml-2"
                                    >
                                        {{ countTodos(getRootMenu("my-varsity")) }}
                                    </span>
                                </RouterLink>
                            </div>

                            <div class="my-2" v-if="myStaffRoute">
                                <RouterLink :to="myStaffRoute">
                                    <template v-if="currentSection !== 'my-staff'">
                                        {{ $t("@app:nav.switch_to") }}
                                    </template>
                                    My Staff
                                    <span
                                        v-if="countTodos(getRootMenu('my-staff')).value > 0"
                                        class="position-absolute top-0 start-100 translate-middle badge rounded-pill badge-dark ml-2"
                                    >
                                        {{ countTodos(getRootMenu("my-staff")) }}
                                    </span>
                                </RouterLink>
                            </div>

                            <div class="my-2" v-if="myOfficeRoute">
                                <RouterLink :to="myOfficeRoute">
                                    <template v-if="currentSection !== 'my-office'">
                                        {{ $t("@app:nav.switch_to") }}
                                    </template>
                                    My Office
                                    <span
                                        v-if="countTodos(getRootMenu('my-office')).value > 0"
                                        class="position-absolute top-0 start-100 translate-middle badge rounded-pill badge-dark ml-2"
                                    >
                                        {{ countTodos(getRootMenu("my-office")) }}
                                    </span>
                                </RouterLink>
                            </div>

                            <div class="my-2" v-if="myAdminRoute">
                                <RouterLink :to="myAdminRoute">
                                    <template v-if="currentSection !== 'my-admin'">
                                        {{ $t("@app:nav.switch_to") }}
                                    </template>
                                    My Admin
                                    <span
                                        v-if="countTodos(getRootMenu('my-admin')).value > 0"
                                        class="position-absolute top-0 start-100 translate-middle badge rounded-pill badge-dark ml-2"
                                    >
                                        {{ countTodos(getRootMenu("my-admin")) }}
                                    </span>
                                </RouterLink>
                            </div>

                            <hr />
                            <button @click="logout()" class="btn btn-link">
                                <i class="fa fa-fw fa-sign-out-alt"></i>
                                Logout
                            </button>
                        </div>

                        <!-- @todo postService -->
                        <!-- <i class="fa fa-fw fa-sync fa-spin" v-if="postService.executionTaskRunning"></i> -->
                    </div>
                </nav>
            </div>
        </div>

        <div class="d-none d-lg-block">
            <ul v-if="activeFirstLevelMenu && activeFirstLevelMenu.children?.length > 1" class="navbar-secondary">
                <VarsityDesktopSecondLevelNavigation :menu="activeFirstLevelMenu" />
            </ul>

            <ul
                v-if="activeSecondLevelMenu && activeSecondLevelMenu.children?.length > 1"
                class="nav nav-2nd-level mb-3"
            >
                <template v-for="item of activeSecondLevelMenu.children">
                    <template v-if="item.children?.length > 0 && item.settings?.appearance !== 'tabs'">
                        <li class="nav-item">
                            <VfDropdown
                                apply-container-width-to-menu
                                menu-class="dropdown-menu d-block nav nav-2nd-level flex-column"
                            >
                                <template v-slot="{ openDropdown }">
                                    <div
                                        class="nav-link"
                                        :class="{
                                            'active': item.hasActiveChildren,
                                            'has-active-children': item.hasActiveChildren,
                                        }"
                                        @click="openDropdown()"
                                    >
                                        <VarsityContentProcessor :content="$t(item.label)"></VarsityContentProcessor>
                                    </div>
                                </template>

                                <template #menu="{ closeDropdown }">
                                    <div class="nav-item" v-for="child of item.children">
                                        <RouterLink
                                            :to="getFirstChildRoutingData(child)"
                                            class="nav-link"
                                            :class="{
                                                'active': child.hasActiveChildren,
                                                'has-active-children': child.hasActiveChildren,
                                            }"
                                            @click="closeDropdown()"
                                        >
                                            <VarsityContentProcessor
                                                :content="$t(child.label)"
                                            ></VarsityContentProcessor>
                                        </RouterLink>
                                    </div>
                                </template>
                            </VfDropdown>
                        </li>
                    </template>
                    <template v-else>
                        <li class="nav-item" v-if="item.label">
                            <RouterLink
                                class="nav-link"
                                :to="getFirstChildRoutingData(item)"
                                :class="{
                                    'active': item.hasActiveChildren,
                                    'has-active-children': item.hasActiveChildren,
                                }"
                            >
                                <VarsityContentProcessor :content="$t(item.label)"></VarsityContentProcessor>
                                <span
                                    v-if="countTodos(item).value > 0"
                                    class="position-absolute top-0 start-100 translate-middle badge rounded-pill badge-light ml-2"
                                >
                                    {{ countTodos(item) }}
                                </span>
                            </RouterLink>
                        </li>
                    </template>
                </template>
            </ul>

            <template v-if="activeThirdLevelMenu && activeThirdLevelMenu.settings?.appearance === 'tabs'">
                <ul v-if="activeThirdLevelMenu.children?.length > 1" class="nav nav-tabs mb-3">
                    <li class="nav-item" v-for="item of activeThirdLevelMenu.children">
                        <RouterLink
                            class="nav-link"
                            :to="getFirstChildRoutingData(item)"
                            :class="{ active: item.hasActiveChildren }"
                        >
                            <VarsityContentProcessor :content="$t(item.label)"></VarsityContentProcessor>
                        </RouterLink>
                    </li>
                </ul>
            </template>

            <ul v-if="activeFourthLevelMenu && activeFourthLevelMenu.children?.length > 1" class="nav nav-tabs mb-3">
                <li class="nav-item" v-for="item of activeFourthLevelMenu.children">
                    <RouterLink
                        class="nav-link"
                        :to="getFirstChildRoutingData(item)"
                        :class="{ active: item.hasActiveChildren }"
                    >
                        <VarsityContentProcessor :content="$t(item.label)"></VarsityContentProcessor>
                    </RouterLink>
                </li>
            </ul>
        </div>

        <template v-if="!isProduction || appConfig.login">
            <AnimateIf :if="navbarState.showCard === 'login'">
                <div>
                    <div class="container">
                        <div class="navbar-login">
                            <VarsityInlineLogin></VarsityInlineLogin>
                        </div>
                    </div>
                </div>
            </AnimateIf>

            <AnimateIf :if="navbarState.showCard === 'registration'">
                <div>
                    <div class="container">
                        <div class="navbar-login">
                            <VarsityInlineRegistration></VarsityInlineRegistration>
                        </div>
                    </div>
                </div>
            </AnimateIf>

            <AnimateIf :if="navbarState.showCard === 'lost-password'">
                <div>
                    <div class="container">
                        <div class="navbar-login">
                            <VarsityInlineLostPassword></VarsityInlineLostPassword>
                        </div>
                    </div>
                </div>
            </AnimateIf>

            <AnimateIf :if="navbarState.showCard === 'reset-password'">
                <div>
                    <div class="container">
                        <div class="navbar-login">
                            <VarsityInlineResetPassword></VarsityInlineResetPassword>
                        </div>
                    </div>
                </div>
            </AnimateIf>

            <div v-if="navbarState.showCard === 'activate'">
                <div>
                    <div class="container">
                        <div class="navbar-login">
                            <Suspense>
                                <VarsityInlineAccountActivation></VarsityInlineAccountActivation>
                            </Suspense>
                        </div>
                    </div>
                </div>
            </div>
        </template>
    </div>
</template>

<style lang="scss">
@import "@/styles/variables";

.vb-header-container {
    display: none;
}

.vb-header {
    display: none;
}

@include media-breakpoint-up(lg) {
    .vb-header-container {
        display: grid;
        grid-template-columns: 20rem 1fr;

        .vb-header-logo {
            grid-column: 1;
            grid-row: 1;

            z-index: 3;
            height: 150px;
            width: 170px;

            display: grid;
            place-content: center;
            position: relative;

            img {
                z-index: 3;
                height: 127px;
            }

            &:before,
            &:after {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                bottom: 0;
                right: 0;
            }

            &:before {
                z-index: 1;
                left: 80px;
                background: linear-gradient(90deg, #a8b4b8, rgba(#a8b4b8, 0));
            }

            &:after {
                background-color: #fff;
                z-index: 2;
                transform: skew(-30deg);
                margin-left: -100px;
            }
        }

        .vb-header-content {
            grid-column: 1 / to 2;
            grid-row: 1;

            display: flex;
            flex-direction: column;
        }

        .navbar {
            margin-left: 200px;
            flex-grow: 1;
            align-items: stretch;
            /* margin-top: 25px; */
        }

        .navbar-collapse,
        .navbar-nav {
            align-items: stretch;
        }
    }

    .vb-header {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        margin-left: 15px;

        .vb-header-section {
            border: 0;
            display: flex;
            align-items: center;
            background-color: $primary;
            color: #fff;
            padding-right: 5.5rem;
            padding-block: 0;

            &:last-child {
                padding-right: 3.5rem;
            }

            &.active {
                background-color: theme-color("primary");
                color: #fdfdfd;
            }

            .fa-globe-europe {
                font-size: 1.5rem;
            }

            .vb-header-search-input {
                margin-left: 200px;
                margin-right: 3rem;

                input[type="search"] {
                    background-color: transparent;
                    border: none;
                    display: block;
                    width: 100%;
                    border-bottom: 1px solid transparent;

                    &:focus {
                        border-bottom: 1px solid #000;
                    }
                }
            }
        }

        .vb-header-section-dark {
            background-color: #0b6fb9;

            &::before {
                content: "";
                width: 42px;
                height: 3.2rem;
                background: linear-gradient(to right, #084e82 0%, #0b6fb9 100%);
                transform: skew(-30deg) translateX(-1.5rem);
            }
        }

        // button.vb-header-section {
        //font-family: "Roboto Slab", sans-serif;
        //font-size:   1.25rem;
        // }

        .social-button {
            font-size: 1.2rem;
            width: 2rem;
            height: 2rem;
            margin-left: 0.1rem;
            margin-right: 0.1rem;
        }
    }
}

.navbar {
    padding: 0;
    box-shadow: none;
    font-family: "Roboto Slab", sans-serif;
    font-size: 1.2rem;
}

.navbar-nav {
    width: 100%;
    justify-content: start;
    align-items: center;
    display: flex;

    .nav-item {
        text-align: center;
        padding-inline: 2rem;
        position: relative;

        a.nav-link {
            display: block;
            padding: 0.5rem 0;
            color: #222;
        }
    }

    .nav-item.active:not(.navbar-nav-my-varsity)::before {
        content: "";
        background-color: $primary-dark;
        width: 24px;
        height: 12px;
        display: block;
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
    }

    .nav-item.active .nav-link {
        color: theme-color("primary") !important;
    }

    .nav-item.navbar-nav-my-varsity {
        transform: skew(-30deg);
        background-color: theme-color("primary");
        //position:         relative;
        cursor: pointer;
        align-self: center;
        min-width: 400px;
        width: 20%;

        box-shadow: 0.75rem 0.75rem 0 theme-color("primary-dark");

        .nav-link,
        .nav-link:hover {
            padding-left: 1rem;
            padding-right: 1rem;
            color: #fdfdfd !important;
            transform: skew(30deg);
            font-family: "Roboto Slab", sans-serif;
        }
    }
}

.navbar-popup {
    position: absolute;
    z-index: 4;
    right: 5rem;
    top: 2.3rem;
    //transform: translateY(-1rem);
    background-color: #eeeeed;
    padding: 2rem;
    box-shadow: 0 0 10px rgba(#000, 0.5);
    margin: 1rem 0 0;
    width: 23rem;

    hr {
        background-color: theme-color("primary");
    }

    h1 {
        font-size: 1.2rem;
    }

    button {
        padding: 0;
        color: #000;
        font-family: "Roboto Slab", sans-serif;
        font-size: 1.4rem;

        i {
            color: theme-color("primary");
            margin-right: 0.2rem;
        }

        &:hover {
            text-decoration: none;
        }
    }
}

.navbar-secondary {
    display: flex;
    list-style: none;
    padding: 0;
    flex-direction: column;
    justify-items: start;
    background-color: $primary-dark;
    margin-bottom: 0;

    @include media-breakpoint-up(lg) {
        flex-direction: row;
        padding-left: 200px;
    }

    .nav-item {
        position: relative;
        background-color: #051f31;
        /* flex-grow: 1; */
        /* flex-basis: 0; */

        //&.active.has-active-children::after {
        //    margin:       auto;
        //    position:     absolute;
        //    content:      "";
        //    width:        0;
        //    height:       0;
        //    border-left:  14px solid transparent;
        //    border-right: 14px solid transparent;
        //
        //    border-top:   14px solid $primary-dark;
        //}
    }

    .nav-item.active {
        background-color: $primary-dark;

        .nav-link {
            color: $primary;
        }
    }

    .nav-link {
        color: #fff;
        font-size: 1.2rem;
        padding: 1.25rem 2rem;
        text-align: center;
        cursor: pointer;
    }
}

.navbar-login {
    background-color: #fff;
    padding: 1rem 0.5rem;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    margin: 1rem 0;

    //.navbar-login-divider {
    //    $divider-width:   5%;
    //    background-color: #fff;
    //    clip-path:        polygon(#{100% - $divider-width} 0%, 100% 0%, $divider-width 100%, 0% 100%);
    //    width:            5rem;
    //    height:           5rem;
    //    margin:           -0.5rem 1rem -0.5rem -1rem;
    //}

    .form-control {
        background-color: #ecebeb;
        border: none;
    }

    .btn {
        filter: drop-shadow(0.5rem 0.75rem 0 #0d2030);
    }

    a {
        font-family: "Roboto Slab", sans-serif;
        text-decoration: underline;
        font-size: 1.2rem;
    }

    .checkbox a {
        font-size: 1rem;
    }
}

@media all and (min-width: 992px) {
    .navbar .nav-item .dropdown-menu {
        display: none;
        background-color: #e6ebe9;
        border: none;
        padding-top: 0.8rem;

        > li {
            margin-bottom: 0.5rem;
            padding-left: 35px;
            font-size: 1.2rem;

            .dropdown-item {
                padding-left: 0.5rem;
                padding-right: 2rem;

                &::before {
                    position: absolute;
                    content: "/";
                    color: $primary;
                    margin-left: -20px;
                    font-weight: bold;
                }

                &:hover,
                &:focus {
                    background-color: #e6ebe9;
                    color: rgba(0, 0, 0, 0.7);
                }
            }
        }
    }
    .navbar .nav-item:hover .dropdown-menu {
        display: block;
    }
    .navbar .nav-item .dropdown-menu {
        margin-top: 0;
    }
}

.navbar-popup hr + hr {
    display: none;
}

/*─────────────────────────────────────┐
│  submenu                             │
└─────────────────────────────────────*/
.dropdown-icon {
    transition: transform 0.2s;
}

.dropdown-icon.is-open {
    transform: rotate(-180deg);
}

.navbar-submenu {
    background: linear-gradient(90deg, #0b5895, #0870ba, #0b5895);
    display: flex;
    flex-direction: column;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    font-family: "Roboto", sans-serif;
    font-size: 15px;
    /* transform: translateX(-25%); */

    a {
        color: #fff;
        line-height: 48px;
        border-bottom: 1px solid #2c80be;
        transition: all 0.2s linear;
        padding-inline: 1rem;
        display: block;
    }

    & > div:last-child a,
    & > a:last-child {
        border-bottom: none;
    }

    a:hover {
        color: #051f31;
        text-decoration: none;
    }
}

.navbar-submenu-dark {
    background: $primary-dark;

    a {
        border-bottom: 1px solid #173a53;
    }

    a:hover {
        color: #0b6fb9;
    }
}
</style>
