import _, { template } from "lodash"

export function resolveConfiguration(partType, config) {
    const resolvedConfig = { ...config }
    for (const field of configurationFields[partType] ?? []) {
        const defaultValue = getDefaultValue(partType, field)
        if (!resolvedConfig[field] && defaultValue !== null) {
            resolvedConfig[field] = _.clone(defaultValue)
        }
    }
    // console.log("resolved configuration for ", partType, config, resolvedConfig)
    return resolvedConfig
}

export function getDefaultValue(partType, field) {
    const elementConfig = defaultValuesPerElement[partType] ?? {}
    let value = elementConfig[field] ?? null
    value ??= defaultValuesByProperty[field] ?? null
    return value
}

export function calculateStyleString(element): string {
    return calculateStyle(element).join(";")
}

export function calculateStyle(element): string[] {
    const instructions = []

    switch (element.config.font) {
        case "Roboto 400":
            instructions.push("font-family: 'Roboto'")
            instructions.push("font-weight: 400")
            break
        case "Roboto 700":
            instructions.push("font-family: 'Roboto'")
            instructions.push("font-weight: 700")
            break
        case "Roboto Slab":
            instructions.push("font-family: 'Roboto Slab'")
            instructions.push("font-weight: 400")
            break
    }
    if (element.config.backgroundColor && element.config.backgroundColor.toLowerCase() !== "#ffffff") {
        instructions.push(`background-color: ${element.config.backgroundColor}`)
    }
    if (element.config.backgroundPosition) {
        instructions.push(`background-position: ${element.config.backgroundPosition}`)
    }
    if (element.config.backgroundSize) {
        instructions.push(`background-size: ${element.config.backgroundSize}`)
    }
    if (element.config.backgroundRepeat) {
        instructions.push(`background-repeat: ${element.config.backgroundRepeat}`)
    }
    if (element.config.margin) {
        instructions.push(`margin-top: ${element.config.margin}rem`)
        instructions.push(`margin-bottom: ${element.config.margin}rem`)
    }
    if (element.config.padding) {
        instructions.push(`padding-top: ${element.config.padding}rem`)
        instructions.push(`padding-bottom: ${element.config.padding}rem`)
    }
    if (element.config.paddingHorizontal) {
        instructions.push(`padding-left: ${element.config.paddingHorizontal}rem`)
        instructions.push(`padding-right: ${element.config.paddingHorizontal}rem`)
    }
    if (element.config.spaceSize) {
        instructions.push(`height: ${element.config.spaceSize}rem`)
    }
    if (element.config.align) {
        instructions.push(`text-align: ${element.config.align}`)
    }
    if (element.config.color) {
        instructions.push(`color: ${element.config.color}`)
    }
    if (element.config.height) {
        instructions.push(`height: ${element.config.height}rem`)
    }
    if (element.config.width) {
        instructions.push(`width: ${element.config.width}`)
    }
    if (element.config.borderRadius) {
        instructions.push(`border-radius: 5px`)
    }
    if (element.config.alignItems) {
        instructions.push(`display: flex`)
        instructions.push(`align-items: ${element.config.alignItems}`)
    }
    if (element.config.justifyContent) {
        instructions.push(`display: flex`)
        instructions.push(`justify-content: ${element.config.justifyContent}`)
    }
    if (element.config.buttonColor) {
        instructions.push(`--btn-bg-color: ${element.config.buttonColor}`)
    }
    if (element.config.buttonTextColor) {
        instructions.push(`--btn-text-color: ${element.config.buttonTextColor}`)
    }
    if (element.config.buttonHoverColor) {
        instructions.push(`--btn-bg-hover-color: ${element.config.buttonHoverColor}`)
        instructions.push(`--btn-bg-active-color: ${element.config.buttonHoverColor}`)
    }
    if (element.config.flexDirection) {
        instructions.push(`--cms-flex-direction-md: ${element.config.flexDirection}`)
        instructions.push(`--cms-flex-direction: ${element.config.flexDirectionMobile ?? element.config.flexDirection}`)
    }
    if (element.config.flexGap) {
        instructions.push(`gap: ${element.config.flexGap}rem`)
    }

    return instructions
}

export const buttonPresets: {
    label: string
    buttonColor: string
    buttonTextColor: string
    buttonHoverColor: string
}[] = [
    {
        label: "Blau",
        buttonColor: "#63a5da",
        buttonTextColor: "#ffffff",
        buttonHoverColor: "#4a90c4",
    },
    {
        label: "Dunkelblau",
        buttonColor: "#0d2030",
        buttonTextColor: "#ffffff",
        buttonHoverColor: "#050c12",
    },
    {
        label: "Rot",
        buttonColor: "#d9534f",
        buttonTextColor: "#ffffff",
        buttonHoverColor: "#c9302c",
    },
]

export const allContainers = [
    "box",
    "sidebyside2",
    "sidebyside3",
    "sidebyside4",
    "picture-gallery",
    /* 'big-pictures' (VB-606) */ "picture-links",
    "picture-text",
    "big-banners",
    "picture-enum",
    "accordion-enum",
    "vertical-space",
    "image-group",
    "timeline",
    "link-list",
]
const genericElements = [
    "heading",
    "text",
    "button",
    "image",
    "pdf",
    "youtube",
    "onlineform",
    "condition",
    "contactform",
    "loginform",
    "faq",
    "side-news",
    "related-news",
    "news-overview",
    "event-overview",
    "anchor",
]
export const availableElements = {
    "box": genericElements,
    "container": genericElements,
    "sidebyside2": genericElements,
    "sidebyside3": genericElements,
    "sidebyside4": genericElements,
    "picture-gallery": ["image"],
    "picture-links": ["picture-link"],
    // 'big-pictures': ['big-picture'], (VB-606)
    "big-banners": ["big-banner"],
    "picture-enum": ["enum-tab"],
    "accordion-enum": ["enum-page"],
    "enum-page": genericElements,
    "picture-text": genericElements,
    "image-group": ["image-group-link"],
    "big-banner": ["button"],
    "timeline": ["timeline-item"],
    "link-list": ["link-list-item"],
}
export const availableContainers = {
    "box": allContainers,
    "container": allContainers,
    "sidebyside2": allContainers,
    "sidebyside3": allContainers,
    "sidebyside4": allContainers,
    "picture-text": allContainers,
    "enum-page": allContainers,
}

const defaultValuesByProperty = {
    backgroundColor: "#ffffff",
    colorBlend: false,
    colorBlendColor: "#63a5da",
    colorBlendMode: "multiply",
    colorBlendOpacity: 50,
    buttonColor: buttonPresets[0].buttonColor,
    buttonTextColor: buttonPresets[0].buttonTextColor,
    buttonHoverColor: buttonPresets[0].buttonHoverColor,
}
const _sideBySideConfigurationFields = [
    "backgroundColor",
    "color",
    "margin",
    "padding",
    "paddingHorizontal",
    "sideBySideLayout",
    "sideBySideAlign",
    "borderRadius",
]
const colorBlend = ["colorBlend", "colorBlendColor", "colorBlendOpacity", "colorBlendMode"]
export const configurationFields = {
    "box": [..._sideBySideConfigurationFields, "flexDirection", "flexDirectionMobile", "flexGap"],
    "sidebyside2": _sideBySideConfigurationFields,
    "sidebyside3": _sideBySideConfigurationFields,
    "sidebyside4": _sideBySideConfigurationFields,
    "heading": [
        "translations",
        "level",
        "align",
        "font",
        "color",
        "backgroundColor",
        "margin",
        "padding",
        "paddingHorizontal",
    ],
    "text": ["translations", "backgroundColor", "margin", "padding", "paddingHorizontal", "align"],
    "button": [
        "translations",
        "buttonLink",
        "target",
        "margin",
        "padding",
        "buttonColor",
        "buttonTextColor",
        "buttonHoverColor",
    ],
    "image": ["align", "buttonLink", "target", "width", "borderRadius", "translations"],
    "pdf": ["translations"],
    "youtube": ["url"],
    "picture-link": ["translations", "buttonLink", "target", "borderRadius", ...colorBlend],
    "picture-text": ["pictureTextLayout", "borderRadius", "translations"],
    "picture-enum": ["align", "pictureEnumType", "pictureEnumFirstTabOpen"],
    "picture-links": ["pictureLinksLayout", "justifyContent"],
    "picture-gallery": ["pictureGalleryLayout"],
    "big-banners": ["fullWidth"],
    "big-banner": [
        "translations",
        "backgroundPosition",
        "backgroundSize",
        "backgroundRepeat",
        "fontSizeHeading",
        "fontSize",
        "borderRadius",
        ...colorBlend,
    ],
    "enum-tab": ["translations", "buttonLink", "target", "borderRadius", "disableOpenTab"],
    "enum-page": ["translations"],
    "vertical-space": ["spaceSize"],
    "image-group-link": ["translations", "buttonLink", "target", "borderRadius"],
    "onlineform": ["translations", "condition-multiple", "selectedDocuments"],
    "timeline-item": ["translations", "align-lr"],
    "contactform": ["translations", "borderRadius", "contactForceTypeTopic", "contactTopicGroup"],
    "condition": [
        "padding",
        "paddingHorizontal",
        "condition-single",
        "selectedDocument",
        "displayType",
        "showPdfDownloadLink",
    ],
    "side-news": ["count", "news-category-filter"],
    "related-news": ["count", "news-category-filter"],
    "news-overview": ["news-category-filter"],
    "event-overview": ["championship-multiple"],
    "anchor": ["name"],
    "link-list": ["translations"],
    "link-list-item": ["translations", "buttonLink", "target"],
    "accordion-enum": ["showSidebar", "accordionEnumFirstTabOpen"],
}
// default if not specified: ["title"]
export const translationFields = {
    "big-banner": ["file", "title", "text"],
    "onlineform": ["title", "text"],
    "contactform": ["title", "text"],
    "enum-tab": ["title", "text", "buttonText", "file"],
    "big-picture": ["title", "text", "buttonText"],
    "enum-page": ["title"],
    "text": ["text"],
    "picture-link": ["file", "title", "buttonText"],
    "picture-text": ["file"],
    "image-group-link": ["file"],
    "timeline-item": ["buttonText", "title", "text"],
    "link-list": ["title"],
    "link-list-item": ["buttonText", "file"],
    "image": ["file", "buttonText", "overlayHeadline", "overlayText"],
    "pdf": ["pdf"],
}
const defaultValuesPerElement = {
    "big-banner": {
        backgroundPosition: "center center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        fontSizeHeading: 5.0,
        fontSize: 2.0,
    },
    "sidebyside2": {
        sideBySideLayout: [6, 6],
        sideBySideAlign: ["start", "start"],
        color: "#000000",
    },
    "sidebyside3": {
        sideBySideLayout: [4, 4, 4],
        sideBySideAlign: ["start", "start", "start"],
        color: "#000000",
    },
    "sidebyside4": {
        sideBySideLayout: [3, 3, 3, 3],
        sideBySideAlign: ["start", "start", "start", "start"],
        color: "#000000",
    },
    "heading": {
        level: 1,
    },
    "onlineform": {
        selectedDocuments: [],
    },
    "condition": {
        selectedDocument: null,
        displayType: "pdf",
        showPdfDownloadLink: false,
        textFactor: 1.0,
    },
    "text": {
        align: "left",
    },
    "news-category-filter": {
        newsCategories: [],
    },
    "box": {
        flexDirection: "column",
    },
}
