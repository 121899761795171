import Quill from "quill"
import { BlockEmbed as BlockEmbedType } from "quill/blots/block"

const BlockEmbed: BlockEmbedType = Quill.import("blots/block/embed")

export class YouTubePlaceholderBlot extends BlockEmbed {
    static blotName = "youtubePlaceholder"
    static tagName = "div"
    static className = "youtube-placeholder"

    // creates the html element that is used to render the blot. the methods `value` and `formats` receive this
    // element as the first argument to construct a delta object from it
    static create(cfg: string) {
        const { youtubeVideoId, placeholderUrl } = JSON.parse(cfg)

        const node = super.create()
        node.setAttribute("style", `background-image: url(${placeholderUrl})`)

        node.setAttribute("data-video-id", youtubeVideoId)
        node.setAttribute("data-placeholder", placeholderUrl)

        return node
    }

    // the value that is used as the blot content ( { insert: { youtubePlaceholder: <value> } } )
    static value(node: HTMLDivElement) {
        return JSON.stringify({
            youtubeVideoId: node.getAttribute("data-video-id"),
            placeholderUrl: node.getAttribute("data-placeholder"),
        })
    }

    // // the value that is used as the blot attributes ( { attributes: <value> } )
    // static formats(node: HTMLDivElement) {
    //     const formats: Record<string, string> = {}

    //     if (node.hasAttribute("data-placeholder")) {
    //         formats.placeholderUrl = node.getAttribute("data-placeholder")
    //     }

    //     console.log("formats of", node, " = ", formats)

    //     return formats
    // }
}
