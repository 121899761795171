<script lang="ts" setup>
import SignaturePad from "@/components/SignaturePad.vue"
import { computed, ref, watch } from "vue"

const props = defineProps<{
    formElement: any
    city?: string
    signature?: string
}>()
const emit = defineEmits<{
    (e: "update:city", city: string): void
    (e: "update:signature", signature: string): void
    (e: "empty", isEmpty: boolean): void
}>()

const signature = ref(props.city || "")
const city = ref(props.signature || "")

watch(signature, value => {
    emit("update:signature", value)
})
watch(city, value => {
    emit("update:city", value)
})

/*
 * Emptiness detection
 */
const isSignatureEmpty = ref(true)
const isCityEmpty = computed(() => !city.value)
function onSignatureEmpty(isEmpty: boolean) {
    isSignatureEmpty.value = isEmpty
}

watch([isSignatureEmpty, isCityEmpty], ([isSignatureEmpty, isCityEmpty]) => {
    emit("empty", isSignatureEmpty || isCityEmpty)
})
</script>

<template>
    <div>
        <label class="d-block font-weight-bold">{{ formElement.text }}</label>
        <div class="signature-container form-control h-100">
            <input type="text" class="border-0" :name="'signatures[' + formElement.key + '][city]'" v-model="city" />

            <SignaturePad class="p-3" v-model="signature" @empty="isEmpty => onSignatureEmpty(isEmpty)"></SignaturePad>

            <div class="signature-label-city py-2">{{ $t("@cms:onlineForm.signature.city") }}</div>
            <div class="signature-label-signature py-2">
                {{ $t("@cms:onlineForm.signature.signature") }}
            </div>
        </div>
        <input type="hidden" :name="'signatures[' + formElement.key + '][image]'" :value="signature" />
    </div>
</template>

<style scoped>
.onlineform-text-content span {
    background-color: transparent !important;
}

.signature-container {
    background-color: #fff;
    display: grid;
}

.signature-container .signature-label-city {
    border-top: 1px solid #333;
    grid-column: 1 / span 1;
    grid-row: 2;
}

.signature-container .signature-label-signature {
    border-top: 1px solid #333;
    grid-column: 2 / span 1;
    grid-row: 2;
}
</style>
