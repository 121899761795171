import { defineStore } from "pinia"
import { ref, watch } from "vue"

export const useAppState = defineStore("app", () => {
    const faqMode = ref(false)
    const helpMode = ref(false)
    const devMode = ref(false)

    function toggleFaqMode() {
        faqMode.value = !faqMode.value
    }

    function toggleHelpMode() {
        helpMode.value = !helpMode.value
    }

    function toggleDevMode() {
        devMode.value = !devMode.value
    }

    watch(helpMode, () => document.body.classList.toggle("help-mode", helpMode.value))

    return {
        faqMode,
        helpMode,
        devMode,
        toggleFaqMode,
        toggleHelpMode,
        toggleDevMode,
    }
})
