import type { VfMenuItem } from "@/composables/useMenuBuilder"
import { useDelayedAction, type DelayedAction } from "@/vf"
import { defineStore } from "pinia"

export const useNavbarDropdownState = defineStore("navbar-dropdown-state", () => {
    const autoCloseMs = 500
    const timers = new Map<number, Map<string, DelayedAction>>()

    function hover(level: number, item: VfMenuItem, openFn?: () => void, closeFn?: () => void) {
        // initialize the level
        timers.set(level, timers.get(level) || new Map())

        // create timer
        if (!timers.get(level).has(item.id)) {
            timers.get(level).set(
                item.id,
                useDelayedAction(autoCloseMs, () => {
                    closeFn()
                }),
            )
        }

        // close all other submenus on the same level
        for (const [, value] of timers.get(level)) {
            if (value === timers.get(level).get(item.id)) {
                continue
            }

            value.stop()
            value.action()
        }

        // stop the timer for the hovered item
        timers.get(level).get(item.id)?.stop()

        // if not already open, open the submenu
        openFn?.()
    }

    function leave(level: number, item: VfMenuItem) {
        timers.get(level)?.get(item.id)?.start()
    }

    function submenuOnClose(level: number, item: VfMenuItem) {
        timers.get(level)?.get(item.id)?.stop()
    }

    return {
        hover,
        leave,
        submenuOnClose,
    }
})
