<script setup lang="ts">
import Flickity from "flickity"
import type { Ref } from "vue"
import { onBeforeUnmount, onMounted, ref } from "vue"
import { calculateStyle } from "../../CmsPage/configuration"
import CmsButtonLink from "./CmsButtonLink.vue"
import { createBackgroundImageColorBlend } from "./color-blend"

// props
const props = defineProps<{
    element: any
}>()

// functions
function attached() {
    if (props.element?.columns?.[0]?.length > 1) {
        isSlider.value = true

        if (!containerElement.value) {
            return
        }

        flickity = new Flickity(containerElement.value, {
            wrapAround: true,
            pageDots: false,
        })
    }
}

// refs
let flickity: Flickity = null
const containerElement: Ref<HTMLDivElement> = ref(null)
const isSlider: Ref<any> = ref(false)

onMounted(() => attached())
onBeforeUnmount(() => flickity?.destroy())
</script>

<template>
    <div
        class="carousel-cell cms-big-banner"
        :style="[...calculateStyle(element), ...createBackgroundImageColorBlend(element.config, element.fileName)]"
    >
        <div class="cms-big-banner--inner">
            <h1
                :style="element.config.fontSizeHeading ? { '--font-size-md': element.config.fontSizeHeading + 'rem' } as any : {}"
            >
                {{ element.title }}
            </h1>
            <h2
                :style="element.config.fontSize ? { '--font-size-md': element.config.fontSize + 'rem' } as any : {}"
                v-html="element.text"
            ></h2>

            <template v-if="element.columns">
                <template v-for="child in element?.columns[0]">
                    <CmsButtonLink class="btn btn-primary" :element="child"></CmsButtonLink>
                </template>
            </template>
        </div>
    </div>
    <!--        <div class="swiper-wrapper">-->
    <!--        </div>-->

    <!--        <div ref="pagePrevElement" class="swiper-button-prev" click.trigger="prevSlide()" if.bind="isSlider">-->
    <!--            <i class="fa fa-fw fa-chevron-left"></i>-->
    <!--        </div>-->
    <!--        <div ref="pageNextElement" class="swiper-button-next" click.trigger="nextSlide()" if.bind="isSlider">-->
    <!--            <i class="fa fa-fw fa-chevron-right"></i>-->
    <!--        </div>-->
</template>

<style scoped lang="scss">
@import "@/styles/variables.scss";

.cms-big-banner {
    /* padding: 6rem 6rem 2rem; */
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    width: 100%;
    height: 28rem;
    padding-inline: 6rem 5rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.cms-big-banner h1 {
    color: #fdfdfd;
    font-size: 2.5rem;
    margin-bottom: 0rem;
    font-weight: bold;

    @include media-breakpoint-up(md) {
        --font-size-md: 5rem;
        font-size: var(--font-size-md);
    }
}

.cms-big-banner h2,
:deep(h2 p) {
    color: #fdfdfd;
    font-size: 1.3rem;
    font-family: "Roboto", sans-serif;
    line-height: 1.9rem;
    margin-block: 2rem;

    @include media-breakpoint-up(md) {
        --font-size-md: 1.5rem;
        font-size: var(--font-size-md);
    }
}

/* .cms-big-banner--inner { */
/*     width: 66.66%; */
/* } */
</style>
