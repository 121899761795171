<script setup lang="ts">
import { useApp, useHttpGet, useSimpleUpload } from "@/vf"
import { computed, reactive } from "vue"
import { type MediaLibraryDirectory, type MediaLibraryFile, type MediaLibraryNode } from "./MediaLibrary"

const emit = defineEmits<{
    (e: "selected", event: string): void
}>()

const { apiLink } = useApp()
const { data, refresh } = useHttpGet<MediaLibraryNode[]>("/cms/media-library", false)

const selectedDirectory: string[] = reactive([])

const node = computed<MediaLibraryNode[]>(() => {
    if (!selectedDirectory) {
        return data.value
    }

    let node = data.value

    for (const directory of selectedDirectory) {
        node = (node.find(item => item.name === directory && item.type === "dir") as MediaLibraryDirectory).children
    }

    return node
})

const directories = computed<MediaLibraryDirectory[]>(() => {
    return node.value?.filter(item => item.type === "dir") as MediaLibraryDirectory[]
})

const files = computed<MediaLibraryFile[]>(() => {
    return node.value?.filter(item => item.type === "file") as MediaLibraryFile[]
})

function enterDirectory(directory: string) {
    selectedDirectory.push(directory)
}

function leaveDirectory() {
    selectedDirectory.pop()
}

const simpleUpload = useSimpleUpload()
async function uploadFile() {
    const basePath = selectedDirectory.join("/")
    const response = await simpleUpload.asyncUpload("cms/media-library/?path=" + encodeURIComponent(basePath))
    await refresh()
    emit("selected", response.data)
}
</script>

<template>
    <div>
        <div v-if="selectedDirectory.length > 0" class="media-library-node" @click="leaveDirectory">
            <div class="mr-2">
                <i class="fa fa-fw fa-arrow-left fa-2x"></i>
            </div>
            <span class="text-muted">
                <span v-for="(directory, index) in selectedDirectory" :key="directory">
                    <span v-if="index > 0" class="mx-1">/</span>
                    <span>{{ directory }}</span>
                </span>
            </span>
        </div>

        <div
            v-for="directory in directories"
            class="media-library-node"
            @click="enterDirectory(directory.name)"
            :key="directory.name"
        >
            <div class="mr-2">
                <i class="fa fa-fw fa-folder fa-2x"></i>
            </div>

            <span>{{ directory.name }}</span>
        </div>

        <div class="media-library-images">
            <div
                v-for="file in files"
                class="media-library-image"
                @click="emit('selected', file.path)"
                :key="file.path"
            >
                <img :src="apiLink(`/media/${file.path}`)" class="img-fluid" />
            </div>
        </div>

        <!-- <div v-for="file in files" class="media-library-node" @click="emit('selected', file.path)" :key="file.path">
        <img :src="apiLink(`/media/${file.path}`)" class="media-library-image" />

        <span>{{ file.name }}</span>
    </div> -->

        <div class="media-library-node" @click="uploadFile">
            <div class="mr-2">
                <i class="fa fa-fw fa-upload fa-2x"></i>
            </div>

            <span>{{ $t("@cms:upload_file") }}</span>
        </div>
    </div>
</template>

<style scoped>
.media-library-node {
    display: flex;
    align-items: center;
    cursor: pointer;
    padding-block: 0.25rem;
}

.media-library-node:hover {
    background-color: rgba(255, 255, 255, 0.2);
}

.media-library-images {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
}

.media-library-image {
    display: grid;
    place-items: center;
    cursor: pointer;
    padding: 0.25rem;
}

.media-library-image:hover {
    background-color: rgba(255, 255, 255, 0.5);
}
</style>
